import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";

import BaseLayout from "../../components/layout";
import "./dashboard.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YouTubeVideo from "../../components/video";
import { getJudgesScores, getCampScores, getPublicScores, getContestants, getScoresForLeaderBoard, postContestant, getMaiden, postJudgeScore } from '../../redux/action';
import getTitleCase from '../../utils/getTitleCase';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import TextInput from '../../components/form/input-box';
import { getJudge, setFormData } from '../../redux/contestant/contestant.action';
import Select from '../../components/form/select';
import InputBox from '../../components/form/input';


const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  // const { maiden, judge } = useSelector(state => state.contestants);
  const { contestants, judgesScores, campScores, publicScores, loading, leaderboard, maiden, judge, formData } = useSelector((state) => state.contestants);
  const contestant = contestants ? contestants.find(contestant => contestant.username === currentUser?.username) : null;
  const totalScore = (contestant && leaderboard) ? leaderboard.find((maiden) => maiden.contestant_id === contestant.id)?.total : '';

  const position = (contestant && leaderboard) ? leaderboard.findIndex((maiden) => maiden.contestant_id === contestant.id) + 1 : '';
  const dispatch = useDispatch();
  const [email, setEmail] = useState(currentUser.email || '')
  const [phone, setPhone] = useState('')
  const navigate = useNavigate();

  const MaidenSelectOptions = (maidens) => {
    if (maidens.length === 0 ) {
      return
    }
    const maidenOptions = maidens.map(maiden => <option key={maiden.id} value={maiden.id}>{maiden.firstname} {maiden.surname}</option>)
    const placeholder = <option disabled="" hidden value="false"></option>
  
    return (
      [placeholder, ...maidenOptions]
    )
  }

  useEffect(() => {
    if (contestants === null) {
      dispatch(getContestants());
    }

    if (contestant) {
      dispatch(getJudgesScores(contestant.id));
      dispatch(getCampScores(contestant.id));
      dispatch(getPublicScores(contestant.id));
      dispatch(getScoresForLeaderBoard());
    }
  }, [contestant, contestants]);

  useEffect(() => {
    dispatch(getMaiden())
    dispatch(getJudge(currentUser.id))
    
  }, [navigate])

  const handleSuccess = (reference) => {
    const data = {
      paymentData: {
        email,
        phone,
        amount: 10000,
        payment_info: reference,
        payment_status: reference?.message || 'Approved',
      },
      paidRegistrationFee: true
    }
    
    dispatch(postContestant(data))

    toast.success('Payment Successful')

    setTimeout(() => {
      window.location.href = `/register`;
    }, 1000)
  };

  const handleFailure = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    toast.error("You closed the payment dialog");
  }

  const initializePayment = usePaystackPayment({
    reference: (new Date()).getTime(),
    email,
    amount: 10000 * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  });

  const statistics = contestant?.statistics ?? maiden?.statistics

  return (
    <BaseLayout>
      { judge ? 
        <div className='dashboard'>
          <div>
            <h1 className='text-2xl'>Welcome {judge?.first_name}</h1>
          </div>
         <div>
            <div className='mt-10'>
              { contestants &&
                <Select
                invalidInput={false}
                options={MaidenSelectOptions(contestants)}
                placeHolderText={'Select a Maiden'}
                onChange={maidenSelected => dispatch(setFormData({maidenSelected}))}
                selected={formData.maidenSelected}
              />
              }
            </div>
            <div className='mt-5'>
              <InputBox
                value={formData.maidenTask}
                htmlAttributes={{
                  placeholder: 'Task',
                }}
                onChange={(maidenTask) => dispatch(setFormData({maidenTask}))}
              />
            </div>
            <div className='mt-5'>
              <InputBox
                value={formData.maidenScore}
                htmlAttributes={{
                  placeholder: 'Score',
                }}
                onChange={(maidenScore) => dispatch(setFormData({maidenScore}))}
              />
            </div>
            <div>
              <Button
                handleClick={() => {
                  const data = {
                    task: formData.maidenTask,
                    score: parseInt(formData.maidenScore),
                    contestant: parseInt(formData.maidenSelected),
                    judge: judge.id
                  }

                  dispatch(postJudgeScore({data}))
                } } 
                className='bg-orange-400 hover:bg-orange-500 text-xl'
              >
                Submit Score
              </Button>
            </div>
          </div>
        </div>
        :
        <div className="dashboard">
        {maiden?.completeRegistration ?
          <>
            <div className="total-scores">
              <p><span className="total-score-score">TOTAL SCORE: </span>{totalScore}</p>
              <p>CURRENT POSITION: <span className="total-score-position">{position}</span></p>
            </div>

            <div className="dashboard-container">
              <div className="container-one">
                <div className="container-one-img">
                  <img src={contestant?.photo.url || maiden?.photo?.url} alt={contestant?.firstname || maiden?.firstname} />
                  <h1 className="text-4xl uppercase">{`${contestant?.firstname || maiden?.firstname} ${contestant?.surname || maiden?.surname}`}</h1>
                  <p className="text-orange-600 text-2xl">
                    {contestant?.state?.includes('State') || maiden?.state?.includes('State') ? 
                      contestant?.state || maiden?.state : `${contestant?.state || maiden?.state} State`}
                  </p>
                </div>

                <div className="container-one-stats">
                  <h1 className="text-2xl">STATISTICS</h1>
                  <div className="mt-4">
                    {
                      statistics?.map((stat, indx) => {
                        return (
                          <div key={stat.id} className={`flex justify-between mb-3 ${indx !== statistics?.length - 1 && 'border-b-2'}`}>
                            <h6>{stat.title}: </h6>{stat.unit}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

              <div className="container-two">
                <h1 className="text-2xl">SCORE BOARD</h1>
                <div className="mb-5" />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h4>CAMP SCORES</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="max-h-[300px] overflow-scroll">
                      {
                        campScores && campScores.campScores?.map((camp, indx) => {
                          return (
                            <div key={camp.id} className={`flex justify-between mb-3 ${indx !== campScores.campScores.length - 1 && 'border-b-2'}`}>
                              <p>{getTitleCase(camp.task)} </p>{camp.vote}
                            </div>
                          )
                        })
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <h4>JUDGES SCORE</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                      <div className="max-h-[300px] overflow-scroll">
                        {
                          judgesScores && judgesScores.judgesScores?.map((judge, indx) => {
                            return (
                              <div key={judge.id} className={`flex justify-between mb-3 ${indx !== judgesScores.judgesScores.length - 1 && 'border-b-2'}`}>
                                <p>{getTitleCase(judge?.task)}: </p>{judge?.score}
                              </div>
                            )
                          })
                        }
                      </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <h4>PUBLIC VOTES</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="max-h-[300px] overflow-scroll">
                        {
                          publicScores && publicScores.publicScores?.map((score, indx) => {
                            return (
                              <div key={score.id} className={`flex justify-between mb-3 ${indx !== publicScores.publicScores.length - 1 && 'border-b-2'}`}>
                                <p>{score.email} </p>{score.vote}
                              </div>
                            )
                          })
                        }
                      </div>
                  </AccordionDetails>
                </Accordion>

              </div>
            </div>
            {contestant?.videoUrl || maiden?.videoUrl && 
              <div className="video">
                <YouTubeVideo videoUrl={contestant?.videoUrl || contestant?.videoUrl} 
                  name={`${contestant?.firstname || maiden?.firstname} ${contestant?.surname || maiden?.surname}`} />
              </div>
            }
          </> :
          maiden?.paidRegistrationFee ?
          <div>
            <h1 className='uppercase text-xl'>Register to setup your Maiden Profile Account</h1>
            <Link to={'/register'}>
              <Button>Go to Registration Form</Button>
            </Link>
          </div>
          :
          <div>
            <h1 className='uppercase text-xl'>You will need to pay a N10,000 registration fee before continuing</h1>
            <div className="mb-2">
              <TextInput 
                title="Email" 
                placeholder='Please enter your email'
                name="email" 
                variant="auto"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                required 
              />
            </div>

            <div className="mb-3">
              <TextInput 
                type="number"
                title="Phone Number" 
                placeholder='Please enter your phone number'
                name="phone" 
                variant="auto"
                value={phone || ''}
                onChange={(e) => setPhone(e.target.value)}
               />
            </div>
 
              <Button
                handleClick={() => (email && phone) ? initializePayment(handleSuccess, handleFailure) : toast.error('Enter name and phone')}
              >Pay Registration Fee</Button>

          </div>
        }
      </div>
      }
    </BaseLayout>
  )
}

export default Dashboard