import React, { useEffect, useState } from 'react'
import "./step14.css"
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, uploadImage, postContestant } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';

const MAX_FILE_SIZE_BYTES = 2 * 1024 * 1024;

const FileInput = ({label, field, fieldAttrb}) => {
  
  const dispatch = useDispatch()
  const [maxSizeExceeded, setMaxSizeExceeded] = useState({
    field: '',
    isExceeded: false
  })

  return (
    <div className='step-9-top'>
      <div>
        <label htmlFor={field}>
          <strong>{label}:</strong>
          <input
            type="file"
            className={(maxSizeExceeded.field === field && maxSizeExceeded.isExceeded) ? 
            'border-red-600' : fieldAttrb ? 'border-green-600' : 'border-gray-200'  }
            onChange={e => {
              const uploadData = e.target.files[0]
              if (uploadData.size > MAX_FILE_SIZE_BYTES) {
                setMaxSizeExceeded({
                  field,
                  isExceeded: true
                })
                return;
              }

              if(uploadData.size < MAX_FILE_SIZE_BYTES) {
                setMaxSizeExceeded({
                  field: '',
                  isExceeded: false
                }) 
                const data = {
                  files: uploadData,
                  name: uploadData.name,
                  type: uploadData.type
                }
                dispatch(uploadImage(data, field))
              }
            }}
          />
        </label>
        <p>Max file size: 2MB.</p>
      </div>
    </div>
  )
}

function Step14({ formData, dispatch }) {
  const { certificateOfOrigin, birthCertificate, photo, meansOfIdentification } = formData;
  const { maiden } = useSelector(state => state.contestants);
  const navigate = useNavigate();

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 12 of 12 -  DOCUMENTS FOR UPLOAD</p>

      <form >

        <FileInput 
          label={'Certificate of Origin'} 
          field='certificateOfOrigin' 
          fieldAttrb={certificateOfOrigin} 
        />

        <FileInput 
          label={'Birth Certificate or Sworn Affidavit'} 
          field='birthCertificate' 
          fieldAttrb={birthCertificate} 
        />

        <FileInput 
          label={'Professional 5X7 Photograph of Applicant'} 
          field='photo' 
          fieldAttrb={photo} 
        />

        <FileInput 
          label={'Means of Identification'} 
          field='meansOfIdentification' 
          fieldAttrb={meansOfIdentification} 
        />
      </form >
      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(11)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => {
            const data = {
              certificateOfOrigin,
              birthCertificate,
              meansOfIdentification,
              photo,
              currentRegPage: 12,
              completeRegistration: true
            }
            dispatch(postContestant(data))
            // navigate('/dashboard')
            window.location.href = '/dashboard';

            
          }} 
          disabled={!(certificateOfOrigin && birthCertificate && meansOfIdentification)}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Submit
        </Button>
      </div>
    </div >
  )
}

export default Step14;

