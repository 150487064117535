import React, {useEffect} from 'react'
import InputBox from '../../form/input';
import Button from '../../button';
import { setFormData, setStep, postContestant, getMaiden } from '../../../redux/action';
import { useSelector } from 'react-redux';

function Step13({ formData, dispatch }) {
  const { maiden, isLastPage } = useSelector(state => state.contestants);

  const {familyEmergencyContactPhone, familyEmergencyContactName, friend,
    arrestedBefore, treatedOfSeriousIllness, haveTerminalIllness,
    onAnyMedication, haveAnyAllergies, specialNeeds,
    haveArtisticTalent, canAddressLargeCrowd, whyYouDecideToParticipate,
    whatYouIntendToDoWithOfficeAndCrown, oneThingYouAreNotProudOf,
    areYouAModel, participateInPageantBefore, moneyImportant,
    kindOfPeopleIntimidateYou, howCompetitiveAreYou, workWellWithTeam,
    homeAddress, phoneNumber, emailAdrress, notExcoMember, isNigerDelta, isBetween18And25, 
    agreeToTerms, surname, firstname, dateOfBirth, age, gender, nationality, state, stateOfResidence, 
    lga, lgaOfResident, statistics, education, referee} = formData;

  const handleSaveProgress = () => {
    const data = {
      familyEmergencyContactPhone, familyEmergencyContactName, friend,
      areYouAModel, participateInPageantBefore, moneyImportant,
      kindOfPeopleIntimidateYou, howCompetitiveAreYou, workWellWithTeam,
      homeAddress, phoneNumber, emailAdrress, notExcoMember, isNigerDelta, isBetween18And25, 
      agreeToTerms, surname, firstname, dateOfBirth, age, gender, nationality, state, stateOfResidence, 
      lga, lgaOfResident, statistics, education: [education], referee: [referee],
      arrestedBefore, treatedOfSeriousIllness, haveTerminalIllness,
      onAnyMedication, haveAnyAllergies, specialNeeds,
      haveArtisticTalent, canAddressLargeCrowd, whyYouDecideToParticipate,
      whatYouIntendToDoWithOfficeAndCrown, oneThingYouAreNotProudOf, currentRegPage: 11
    }
    dispatch(postContestant(data))
    dispatch(setFormData({
      isLastPage: true
    }))
  }

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden && maiden.friend.length > 0) {
      dispatch(setFormData({
        familyEmergencyContactPhone: maiden.familyEmergencyContactPhone || familyEmergencyContactPhone,
        familyEmergencyContactName: maiden.familyEmergencyContactName || familyEmergencyContactName,
        friend: maiden.friend
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <h4 className="wrap_terms">Step 11 of 12 -  FURTHER SECURITY QUESTIONS</h4>

      <form className='step4-form mt-8'>
        <p className='font-bold -mb-8'>Who is the one person we could call as your character witness among your family members?</p>
        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Full Name of the family member',
              }}
              value={familyEmergencyContactName}
              onChange={familyEmergencyContactName => dispatch(setFormData({familyEmergencyContactName}))}
            />
          </div>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Phone Number',
                pattern: "[0-9 -]{0,12}",
                isPhoneInput: true
              }}
              value={familyEmergencyContactPhone}
              onChange={familyEmergencyContactPhone => dispatch(setFormData({familyEmergencyContactPhone}))}
            />
          </div>
        </div>

        <div className='step-10'>
        <p className='font-bold mb-2'>List the names and phone numbers of your 3 closest friends below:</p>
        </div>
        <hr />

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Full Name (Friend 1)',
              }}
              value={friend[0]?.fullName || ''}
              onChange={(fullName) => {
                friend[0].fullName = fullName
                dispatch(setFormData({
                  friend 
                }))
              }}
            />
          </div>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Phone Number (Friend 1)',
                pattern: "[0-9 -]{0,12}",
                isPhoneInput: true
              }}
              value={friend[0]?.phoneNumber || ''}
              onChange={(phoneNumber) => {
                friend[0].phoneNumber = phoneNumber
                dispatch(setFormData({
                  friend 
                }))
              }}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
              <InputBox
                htmlAttributes={{
                  placeholder: 'Full Name (Friend 2)',
                }}
                value={friend[1]?.fullName || ''}
                onChange={(fullName) => {
                  friend[1].fullName = fullName
                  dispatch(setFormData({
                    friend 
                  }))
                }}
              />
          </div>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Phone Number (Friend 2)',
                pattern: "[0-9 -]{0,12}",
                isPhoneInput: true
              }}
              value={friend[1]?.phoneNumber || ''}
              onChange={(phoneNumber) => {
                friend[1].phoneNumber = phoneNumber
                dispatch(setFormData({
                  friend 
                }))
              }}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Full Name (Friend 3)',
              }}
              value={friend[2]?.fullName || ''}
              onChange={(fullName) => {
                friend[2].fullName = fullName
                dispatch(setFormData({
                  friend 
                }))
              }}
            />
          </div>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Phone Number (Friend 3)',
                pattern: "[0-9 -]{0,12}",
                isPhoneInput: true
              }}
              value={friend[2]?.phoneNumber || ''}
              onChange={(phoneNumber) => {
                friend[2].phoneNumber = phoneNumber
                dispatch(setFormData({
                  friend 
                }))
              }}
            />
          </div>
        </div>
      </form >

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(10)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        { isLastPage ?
          <Button
            variant='normal'
            handleClick={handleSaveProgress} 
            disabled={!(familyEmergencyContactPhone && familyEmergencyContactName && friend.every(({ fullName, phoneNumber }) => fullName && phoneNumber))}
            className='bg-orange-400 hover:bg-orange-500 text-xl'>
            Save Progress
          </Button> :

          <Button
            variant='normal'
            handleClick={() => dispatch(setStep(12)) } 
            disabled={!(familyEmergencyContactPhone && familyEmergencyContactName && friend.every(({ fullName, phoneNumber }) => fullName && phoneNumber))}
            className='bg-orange-400 hover:bg-orange-500 text-xl'>
            Next
            </Button>

        }
      </div>
      {(familyEmergencyContactPhone && familyEmergencyContactName && friend.every(({ fullName, phoneNumber }) => fullName && phoneNumber)) &&
        <span
          onClick={handleSaveProgress} 
          className='text-md text-black mt-4 text-center block underline cursor-pointer'>
          Save Progress
        </span>
      }
    </div >)
}

export default Step13;