import { Link } from 'react-router-dom';
import React, { useState } from 'react'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import "./navbar.css"
import logoImg from '../../images/fondcup-logo-light.png';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../redux/action';

const MENU_ITEMS = [
  {
    label: 'Maidens',
    path: '/',
    id: 1,
    target: '_self',
  },
  {
    label: 'Home',
    path: 'https://fondcup.ng',
    id: 2,
    target: '_blank',
  },
  {
    label: 'Register',
    path: '/terms-and-condition',
    id: 3,
    target: '_self',
  }
];

function Navbar() {
  const [clicked, setClicked] = useState(true);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);


  const loggedIn = !!currentUser ? true : false;

  //Mobile Icon view/change
  const handleClick = () => {
    setClicked(clicked => !clicked)
  }

  return (
    <div className='nav'>
      <div className="nav_logo">
        <Link to='/'><img className="nav_icon" src={logoImg} width="80%" alt="" /></Link>
      </div>

      <div className="menu_icons" onClick={handleClick}>{clicked ? <MenuRoundedIcon sx={{ fontSize: 40 }} /> : <MenuOpenRoundedIcon sx={{ fontSize: 40 }} />}
      </div>

      < div className={clicked ? "nav_menu" : "nav_menu active"}>
        {MENU_ITEMS.map((item) => (
          item.target === '_self' ?
            <Link to={item.path} key={item.id} className="a">
              <a href='/#'>{item.label}</a>
            </Link> :
            <a href={item.path} className="a" key={item.id}>
              {item.label}
            </a>
        ))}
        {
          loggedIn && 
          <Link to={'/dashboard'} className="a">
            <a href='/dashboard'>Dashboard</a>
          </Link>
        }
        { !loggedIn ? (
          <Link to="/login" className="btn">
            <button>LOGIN</button>
          </Link>
          ) : (
            <div className="btn">
              <button onClick={() => dispatch(signOutAction())}>LOGOUT</button>
            </div>
          )
        }

      </div >
    </div >)
}

export default Navbar