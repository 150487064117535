import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BeatLoader } from 'react-spinners';
import BaseLayout from '../../components/layout';
import "../login/login.css";
import logoImg from '../../images/Login-logo.png';
import { signUpAction, resendEmail } from '../../redux/action';
import { Link } from 'react-router-dom';

const initialValues = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  privacyPolicy: false,
};
const validateSchema = Yup.object({
  username: Yup.string().required("Required"),
  email: Yup.string().email("Insert a valid email").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Required"),
  privacyPolicy: Yup.bool().oneOf([true], "Required")
});

export default function SignUp() {
  const [email,setEmail] = useState();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  return (
    <BaseLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={(values, { resetForm }) => {
          // console.log('values', values);
          dispatch(signUpAction(values));
          setEmail(values.email);

          resetForm();
        }}
      >
        {(formik) => {
          // const { errors, touched, isValid, dirty } = formik;
          return (
            <div className="pt-[100px] sm:pt-[90px] md:pt-[130px] pl-5 pr-5 sm:pl-14 sm:pr-14 pb-14 ">
              <div className="login_wrapper">
                <Form className="form">
                  <img src={logoImg} alt="Fondcup Logo" />
                  {email &&  (
                    <>
                        <br />
                        <small>
                            If you haven't received our email, <button onClick={() => resendEmail(email)}>click here</button> to resend it.
                        </small>
                    </>
                  )}
                  <div className="form-item">
                    <Field type="username" name="username" id="username" placeholder="Username" />
                    <ErrorMessage name="username" />
                  </div>

                  <div className="form-item">
                    <Field type="email" id="email" name="email" placeholder="Email" />
                    <ErrorMessage name="email" />
                  </div>


                  <div className="form-item">
                    <Field type="password" id="password" name="password" placeholder="Password" />
                    <ErrorMessage name="password" />
                  </div>

                  <div className="form-item">
                    <Field type="password" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" />
                    <ErrorMessage name="confirmPassword" />
                  </div>

                  <div className="form-item">
                    <label htmlFor="privacyPolicy" className='flex'>
                        <Field type="checkbox" name="privacyPolicy" className="w-[15px] mr-2" />
                        I accept the {' '}<a href="/#"> Privacy Policy </a>{' '} terms and conditions.
                    </label>
                    <div className="error"><ErrorMessage name="privacyPolicy" /></div>
                  </div>

                  <button
                    type="submit"
                    className={"login-btn"}
                    // disabled={!(dirty && isValid)}
                  >
                    {loading ? 
                      <>
                        Loading <BeatLoader size={10} color={"#fff"} />
                      </> : 
                      "Signup"
                    }
                  </button>
                  <div className="reg">
                    <p>Have an account?<Link to={'/login'}><a href="/#"> Login</a></Link> </p>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};