import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './card.css';
import { Link } from 'react-router-dom';
import { transformImageURL } from '../../utils/constants';

const Card = ({ state, image, name, username, onVoteClick, contestantID, totalScore }) => {
  return (
    <div className="card_wrapper">
      <div className='overflow-hidden h-[400px]'>
        <img src={transformImageURL(image)} className="w-full h-auto object-fit" alt={name} />
      </div>
      <div className="card_details">
        <p>{state}</p>
        <h1 className='flex items-center justify-center'>{name} 
          {totalScore &&
          <span className='bg-white transition-all not-italic ease-in-out delay-150 hover:bg-gray-100 text-gray-800 font-semibold py-1 px-2 text-[18px] ml-2 hover:border hover:border-gray-400 rounded-sm shadow'>
            {totalScore}
          </span>
          }
        </h1>

        <Link to={`/maiden/${username}`} className="profile">
          <button className="button" type="button" data-hover={`${name.split(' ')[0]}'s Profile`} data-active="CLICKED">
            <span>
              View Profile <DoubleArrowIcon />
            </span>
          </button>
        </Link>

      </div>
      {/* <div className="frame"><span />
        <button className="custom-btn btn-11" onClick={() => onVoteClick(name, contestantID, username)}>
          Vote <HowToRegOutlinedIcon />
        </button>
      </div> */}
    </div>
  )
}

export default Card;