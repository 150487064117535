import React, {useEffect} from 'react'
import InputBox from '../../form/input';
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';
import { postContestant, setFormData, setStep, getMaiden } from '../../../redux/action';


function Step8() {
  const { formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();

  const { education, statistics, surname, firstname, dateOfBirth, age, gender,
    nationality, state, stateOfResidence, lga, lgaOfResident,
    homeAddress, phoneNumber, emailAdrress } = formData

  const { qualification, nameOfInstitution, level, department, 
    course, postHeld, yearOfGraduation } = education;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden && maiden.education.length > 0) {
      dispatch(setFormData({
        education: maiden.education[0],
      }))
    }
  }, [])

  const handleSaveProgress = () => {
    const data = {
      homeAddress, phoneNumber, emailAdrress, notExcoMember: true, isNigerDelta: true, isBetween18And25: true, 
      agreeToTerms: true, surname, firstname, dateOfBirth, age, gender, nationality, state, stateOfResidence, 
      lga, lgaOfResident, statistics, education: [education], currentRegPage: 6
    }
    dispatch(postContestant(data))
  }

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 6 of 12 -  EDUCATIONAL QUALIFICATION</p>

      <form className='step4-form'>
        <p>Educational Qualification (Highest)</p>
        <br />
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={qualification}
              htmlAttributes={{
                placeholder: 'Qualification',
              }}
              onChange={(qualification) => dispatch(setFormData({
                education: {
                  ...education,
                  qualification
                }
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <label htmlFor="institution"></label>
            <InputBox
              value={nameOfInstitution}
              htmlAttributes={{
                placeholder: 'Name of Institution',
              }}
              onChange={(nameOfInstitution) => dispatch(setFormData({
                education: {
                  ...education,
                  nameOfInstitution
                }
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={level}
              htmlAttributes={{
                placeholder: 'Level',
              }}
              onChange={(level) => dispatch(setFormData({
                education: {
                  ...education,
                  level
                }
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={department}
              htmlAttributes={{
                placeholder: 'Department',
              }}
              onChange={(department) => dispatch(setFormData({
                education: {
                  ...education,
                  department
                }
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <label htmlFor="course">
            </label>
            <InputBox
              value={course}
              htmlAttributes={{
                placeholder: 'Course of Study',
              }}
              onChange={(course) => dispatch(setFormData({
                education: {
                  ...education,
                  course
                }
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={postHeld}
              htmlAttributes={{
                placeholder: 'Post Held (if any)',
              }}
              onChange={(postHeld) => dispatch(setFormData({
                education: {
                  ...education,
                  postHeld
                }
              }))}
            />
          </div>
        </div>
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={yearOfGraduation}
              htmlAttributes={{
                placeholder: 'Graduation Year',
              }}
              onChange={(yearOfGraduation) => dispatch(setFormData({
                education: {
                  ...education,
                  yearOfGraduation
                }
              }))}
            />
          </div>
        </div>
      </form>

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(5)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(7)) } 
          disabled={!(yearOfGraduation && postHeld && course && level && department && nameOfInstitution && qualification )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>
        {(yearOfGraduation && postHeld && course && level && department && nameOfInstitution && qualification ) &&
        <span
          onClick={handleSaveProgress} 
          className='text-md text-black mt-4 text-center block underline cursor-pointer'>
          Save Progress
        </span>
      }
    </div>
  )
}

export default Step8;