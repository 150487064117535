import React, { useEffect } from 'react'
import InputBox from '../../form/input';
import Button from '../../button';
import { setFormData, setStep, getMaiden } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';


function Step7() {
  const { formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();

  const { homeAddress, phoneNumber, emailAdrress } = formData;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    if(maiden) {
      dispatch(setFormData({
        homeAddress: maiden.homeAddress,
        phoneNumber: maiden.phoneNumber,
        emailAdrress: maiden.emailAdrress
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 5 of 12 -  CONTACT INFORMATION</p>

      <form className='step4-form'>
        <br />
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={homeAddress}
              htmlAttributes={{
                placeholder: 'Home Address',
              }}
              onChange={homeAddress => dispatch(setFormData({homeAddress}))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              type='number'
              value={phoneNumber}
              htmlAttributes={{
                placeholder: 'Phone Number',
                pattern: "[0-9 -]{0,11}",
              }}
              onChange={phoneNumber => dispatch(setFormData({phoneNumber}))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={emailAdrress}
              htmlAttributes={{
                placeholder: 'Email Address',
              }}
              onChange={emailAdrress => dispatch(setFormData({emailAdrress}))}
            />
          </div>
        </div>
      </form>

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(4)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(6)) } 
          disabled={!(homeAddress && phoneNumber && emailAdrress )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>
    </div>
  )
}

export default Step7;