import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

import "./registrationForm.css"
import BaseLayout from '../../components/layout';
import Step3 from "../../components/registration/step3";
import Step4 from "../../components/registration/step4";
import Step5 from "../../components/registration/step5";
import Step6 from "../../components/registration/step6";
import Step7 from "../../components/registration/step7";
import Step8 from "../../components/registration/step8";
import Step9 from "../../components/registration/step9";
import Step10 from "../../components/registration/step10";
import Step11 from "../../components/registration/step11";
import Step12 from "../../components/registration/step12";
import Step13 from "../../components/registration/step13";
import Step14 from "../../components/registration/step14";
import { getMaiden, setStep } from "../../redux/action";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const { step, formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMaiden())


    if(maiden){
      maiden.payRegistrationFee === false && navigate('/dashboard')
      dispatch(setStep(maiden.currentRegPage || 1))
    }
  }, [navigate])

  const formStep = (stepNum) => {
    return (
      {
        1: <Step3 />,
        2: <Step4 />,
        3: <Step5 />,
        4: <Step6 />,
        5: <Step7 />,
        6: <Step8 />,
        7: <Step9 formData={formData} dispatch={dispatch} />,
        8: <Step10 formData={formData} dispatch={dispatch} />,
        9: <Step11 formData={formData} dispatch={dispatch} />,
        10: <Step12 formData={formData} dispatch={dispatch} />,
        11: <Step13 formData={formData} dispatch={dispatch} />,
        12: <Step14 formData={formData} dispatch={dispatch} />,
      }[stepNum]
    )
  }

  return (
    <>
      { maiden && maiden.paidRegistrationFee &&
        <BaseLayout>
          <div className="pt-[100px] sm:pt-[90px] md:pt-[130px] pl-5 pr-5 sm:pl-14 sm:pr-14 pb-14 ">
            <h1 className="text-center font-bold">FONDCUP REGISTRATION</h1>
            <ProgressBar
              completed={parseInt(step/12 * 100)}
              bgColor="lightgreen"
              labelAlignment="center"
              labelColor="#e8090"
              maxCompleted={100}
            />
            {formStep(step)}
          </div>
        </BaseLayout>
      }
    </>
  )
}

export default RegistrationForm;

