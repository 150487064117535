import ContestantActionTypes from "./contestant.type";

const INITIAL_STATE = {
  contestants: null,
  maiden: null,
  completeRegistration: false,
  error: null,
  loading: false,
  judge: null,
  judgesScores: null,
  campScores: null,
  publicScores: null,
  leaderboard: null,
  publicVoter: {
    email: '',
    phone: '',
    votes: '',
    amount: '',
  },
  formData: {
    maidenSelected: '',
    maidenTask: '',
    maidenScore: '',
    isLastPage: false,
    notExcoMember: false,
    isNigerDelta: false,
    isBetween18And25: false,
    agreeToTerms: false,
    surname: '',
    firstname: '',
    dateOfBirth: '',
    age: '',
    gender: '',
    nationality: 'Nigeria',
    state: '',
    lga: '',
    stateOfResidence: '',
    lgaOfResident: '',
    statistics: [
      {
        title: 'Weight',
        unit: ''
      },
      {
        title: 'Height',
        unit: ''
      },
      {
        title: 'Burst',
        unit: ''
      },
      {
        title: 'Waist',
        unit: ''
      },
      {
        title: 'Hip',  
        unit: ''
      },
      {
        title: 'Eye Color',
        unit: ''
      },
      {
        title: 'Hair Color',
        unit: ''
      },
      {
        title: 'Shoe Size',
        unit: ''
      },
      {
        title: 'Tee Shirt Size',
        unit: ''
      }
    ],
    homeAddress: '',
    phoneNumber: '',
    emailAdrress: '',
    education: {
      qualification: '',
      nameOfInstitution: '',
      level: '',
      department: '',
      course: '',
      yearOfGraduation: '',
      postHeld: '',
    },
    referee: {
      name: '',
      phone: '',
      address: '',
      email: '',
    },
    areYouAModel: '',
    participateInPageantBefore: '',
    moneyImportant: '',
    kindOfPeopleIntimidateYou: '',
    howCompetitiveAreYou: '',
    workWellWithTeam: '',
    haveArtisticTalent: '',
    canAddressLargeCrowd: '',
    whyYouDecideToParticipate: '',
    whatYouIntendToDoWithOfficeAndCrown: '',
    oneThingYouAreNotProudOf: '',
    arrestedBefore: '',
    treatedOfSeriousIllness: '',
    haveTerminalIllness: '',
    onAnyMedication: '',
    haveAnyAllergies: '',
    specialNeeds: '',
    familyEmergencyContactName: '',
    familyEmergencyContactPhone: '',
    friend: [
      {fullName: '', phoneNumber: ''},
      {fullName: '', phoneNumber: ''},
      {fullName: '', phoneNumber: ''}
    ],
    certificateOfOrigin: '',
    birthCertificate: '',
    photo: '',
    meansOfIdentification: '',
  },

  step: 1,
  tocStep: 1,
}

const contestantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContestantActionTypes.CONTESTANTS_START:
    case ContestantActionTypes.FETCH_JUDGES_SCORES_START:
    case ContestantActionTypes.FETCH_CAMP_SCORES_START:
    case ContestantActionTypes.FETCH_PUBLIC_SCORES_START:
    case ContestantActionTypes.POST_PUBLIC_SCORE_START:
    case ContestantActionTypes.POST_JUDGE_SCORE_START:
    case ContestantActionTypes.POST_CONSTESTANT_START:
    case ContestantActionTypes.GET_MAIDEN_START:
    case ContestantActionTypes.GET_JUDGE_START:
      return {
        ...state,
        loading: true,
      };
    case ContestantActionTypes.CONTESTANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contestants: action.payload,
        error: null,
      };
    case ContestantActionTypes.FETCH_JUDGES_SCORES_FAILURE:
    case ContestantActionTypes.FETCH_CAMP_SCORES_FAILURE:
    case ContestantActionTypes.FETCH_PUBLIC_SCORES_FAILURE:
    case ContestantActionTypes.CONTESTANTS_FAILURE:
    case ContestantActionTypes.POST_PUBLIC_SCORE_FAILURE:
    case ContestantActionTypes.POST_JUDGE_SCORE_FAILURE:
    case ContestantActionTypes.GET_LEADERBOARD_FAILURE:
    case ContestantActionTypes.POST_CONSTESTANT_FAILURE:
    case ContestantActionTypes.GET_MAIDEN_FAILURE:
    case ContestantActionTypes.GET_JUDGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ContestantActionTypes.FETCH_JUDGES_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        judgesScores: action.payload,
      };
    case ContestantActionTypes.FETCH_CAMP_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        campScores: action.payload,
      };
    case ContestantActionTypes.FETCH_PUBLIC_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        publicScores: action.payload,
      };
    case ContestantActionTypes.GET_MAIDEN_SUCCESS:
      return {
        ...state,
        loading: false,
        maiden: action.payload,
      };
    case ContestantActionTypes.SET_PUBLIC_USER_DATA:
      return {
        ...state,
        publicVoter: {
          ...state.publicVoter,
          ...action.payload,
        },
      };
    case ContestantActionTypes.POST_PUBLIC_SCORE_SUCCESS:
    case ContestantActionTypes.POST_JUDGE_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ContestantActionTypes.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload,
      };
    case ContestantActionTypes.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case ContestantActionTypes.SET_TOC_STEP:
      return {
        ...state,
        tocStep: action.step,
      };
    case ContestantActionTypes.GET_JUDGE_SUCCESS:
      return {
        ...state,
        judge: action.payload
      }
    case ContestantActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.hash,
        }
        
      };
    default:
      return state;
  }
}

export default contestantReducer;