import React, { useEffect } from 'react'
import Radio from '../../form/radio';
import Button from '../../button';
import { setFormData, setStep, getMaiden } from '../../../redux/action';
import { useSelector } from 'react-redux';

function Step11({ formData, dispatch }) {

  const { maiden } = useSelector(state => state.contestants);

  const { haveArtisticTalent, canAddressLargeCrowd, whyYouDecideToParticipate,
    whatYouIntendToDoWithOfficeAndCrown, oneThingYouAreNotProudOf } = formData;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden) {
      dispatch(setFormData({
        haveArtisticTalent: maiden.haveArtisticTalent || haveArtisticTalent,
        canAddressLargeCrowd: maiden.canAddressLargeCrowd || canAddressLargeCrowd,
        whyYouDecideToParticipate: maiden.whyYouDecideToParticipate || whyYouDecideToParticipate,
        whatYouIntendToDoWithOfficeAndCrown: maiden.whatYouIntendToDoWithOfficeAndCrown || whatYouIntendToDoWithOfficeAndCrown,
        oneThingYouAreNotProudOf: maiden.oneThingYouAreNotProudOf || oneThingYouAreNotProudOf
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 9 of 12 -  ADDITIONAL INFORMATION</p>

      <form className='step4-form'>
        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Do you have any artistic or remarkable talent?</strong>
            <Radio
              name='talent'
              onChange={() => dispatch(setFormData({
                haveArtisticTalent: true
              }))}
              htmlAttributes={{
                checked: haveArtisticTalent
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='talent'
              onChange={() => dispatch(setFormData({
                haveArtisticTalent: false
              }))}
              htmlAttributes={{
                checked: haveArtisticTalent === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>

          <div className='step4-inputs-one'>
            <strong>Can you address a large crowd confidently?</strong>
            <Radio
              name='confident'
              value={'true'}
              onChange={() => dispatch(setFormData({
                canAddressLargeCrowd: true
              }))}
              htmlAttributes={{
                checked: canAddressLargeCrowd
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='confident'
              value={'false'}
              onChange={() => dispatch(setFormData({
                canAddressLargeCrowd: false
              }))}
              htmlAttributes={{
                checked: canAddressLargeCrowd === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <label htmlFor="reasonForParticipating">
              <strong>
                In less than 200 words, tell us why you decided to participate in FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) 2022 competition?
              </strong>
              <textarea
                className='step9-secondline'
                value={whyYouDecideToParticipate}
                onChange={(e) => dispatch(setFormData({
                  whyYouDecideToParticipate: e.target.value
                }))}
              />
            </label>
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <label htmlFor="ifaWinner">
              <strong>
                If you emerge winner of the FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) 2022 competition, what do you intend to do with your Office and Crown for the Niger Delta people in the next one year? (Not more than 300 words)
              </strong>
              <textarea
                value={whatYouIntendToDoWithOfficeAndCrown}
                className='step9-secondline'
                onChange={(e) => dispatch(setFormData({
                  whatYouIntendToDoWithOfficeAndCrown: e.target.value
                }))}
              />
            </label>
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <label htmlFor="notProudOf">
              <strong>
                What is the one thing you have done that you are <i>not</i> proud of? (Not more than 250 words)
              </strong>
              <textarea
                value={oneThingYouAreNotProudOf}
                className='step9-secondline'
                onChange={(e) => dispatch(setFormData({
                  oneThingYouAreNotProudOf: e.target.value
                }))}
              />
            </label>
          </div>
        </div>
      </form>
      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(9)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(10)) } 
          disabled={!((haveArtisticTalent === true || haveArtisticTalent === false) && 
            (canAddressLargeCrowd === true || canAddressLargeCrowd === false) && whyYouDecideToParticipate && 
            whatYouIntendToDoWithOfficeAndCrown && oneThingYouAreNotProudOf )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>
    </div>
  )
}
export default Step11;