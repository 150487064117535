import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { postPublicScore, setPublicUserData } from "../../redux/action";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";

import Button from "../button";
import Modal from "../modal";
import TextInput from "../form/input-box";


export default function VoteModal({contestant, onClose, onSubmit}) {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { publicVoter } = useSelector((state) => state.contestants);
  
  useEffect(() => {
    if(!!contestant) {
      setIsOpen(true)
    }
  }, [])

  const closeModal = () => {
      setIsOpen(false);
      onClose();
  }

  const handleSuccess = (reference) => {
    const data = {
      email: publicVoter.email,
      phone: publicVoter.phone,
      vote: publicVoter.votes,
      amount: publicVoter.amount,
      contestant: contestant.contestantID,
      payment_info: reference,
      payment_status: reference?.message || 'Approved',
    }
    
    dispatch(postPublicScore({ data }))

    toast.success('Payment Successful')
    closeModal();

    setTimeout(() => {
      window.location.href = `/maiden/${contestant.username}`;
    }, 1000)
  };

  const handleFailure = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    toast.error("You closed the payment dialog");
  }

  const initializePayment = usePaystackPayment({
    reference: (new Date()).getTime(),
    email: publicVoter?.email,
    amount: publicVoter?.amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  });

  return (
      <Modal isOpen={isOpen}>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 sm:p-6 sm:pb-4">
            <div className="flex justify-between w-full">
              <h3 className="mb-5 text-lg font-bold leading-6 text-[#4E5D78]" id="modal-title">
                  Vote {contestant && contestant.name} 
              </h3>
              <button
                onClick={closeModal}
              >
                <AiOutlineCloseCircle />
              </button>
            </div>
            <hr className="mb-3" />

            <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left overflow-y-scroll">

            <div className="mb-2">
              <TextInput 
                title="Email" 
                placeholder='Please enter your email'
                name="email" 
                variant="auto"
                value={publicVoter?.email || ''}
                onChange={(e) => dispatch(setPublicUserData({email: e.target.value}))}
                required 
              />
            </div>

            <div className="mb-3">
              <TextInput 
                type="number"
                title="Phone Number" 
                placeholder='Please enter your phone number'
                name="phone" 
                variant="auto"
                value={publicVoter?.phone || ''}
                onChange={(e) => dispatch(setPublicUserData({phone: e.target.value}))}
               />
            </div>

            <div className="mb-3">
              <TextInput 
                type="number"
                title="Votes" 
                placeholder='Enter number of votes, minimum of 10'
                name="votes" 
                variant="auto"
                value={publicVoter?.votes || ''}
                onChange={(e) => dispatch(setPublicUserData({
                  votes: e.target.value,
                  amount: e.target.value * 100
                }))}
               />
            </div>
              <h4 className="font-bold text-lg">Amount Payable: ₦ {publicVoter?.amount || 0}</h4>

            </div>

            <div className="py-3">
              <Button
                onClick={() => {
                  publicVoter?.votes >= 10 ? initializePayment(handleSuccess, handleFailure) : toast.error('Minimum of 10 votes')
                }}
                disabled={!(publicVoter?.email && publicVoter?.votes && publicVoter?.amount > 999)}
                className="w-full mt-1"
              >
                Give your Vote
              </Button>
            </div>
          </div>

    </div>
  </Modal>
  )
}