import React, { useEffect } from 'react'
import InputBox from '../../form/input';
import { setFormData, setStep, getMaiden } from '../../../redux/action';
import Button from '../../button';
import { useSelector } from 'react-redux';


function Step9({formData, dispatch}) {
  const { maiden } = useSelector(state => state.contestants);
  const { referee } = formData;
  const { name, phone, address, email } = referee;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden && maiden.referee.length > 0) {
      dispatch(setFormData({
        referee: maiden.referee[0],
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 7 of 12 -  REFEREE DETAILS</p>

      <form className='step4-form'>
        <p>REFEREE </p>
        <br />
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={name}
              htmlAttributes={{
                placeholder: 'Name of Referee',
              }}
              onChange={(name) => dispatch(setFormData({
                referee: {
                  ...referee,
                  name
                }
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              type='number'
              value={phone}
              htmlAttributes={{
                placeholder: 'Phone Number',
                pattern: "[0-9 -]{0,11}",
              }}
              onChange={(phone) => dispatch(setFormData({
                referee: {
                  ...referee,
                  phone
                }
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={address}
              htmlAttributes={{
                placeholder: 'Residential/Office Address',
              }}
              onChange={(address) => dispatch(setFormData({
                referee: {
                  ...referee,
                  address
                }
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={email}
              htmlAttributes={{
                placeholder: 'Email Address (if any)',
                // pattern: "[a-z0-9._%+-]{1,40}[@]{1}[a-z]{1,10}[.]{1}[a-z]{2,}",
              }}
              onChange={(email) => dispatch(setFormData({
                referee: {
                  ...referee,
                  email
                }
              }))}
            />
          </div>
        </div>
      </form>

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(6)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(8)) } 
          disabled={!(name && phone && address && email )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>

    </div>
  )
}

export default Step9;