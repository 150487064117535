import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LeaderBoard from '../../components/leader-board';
import { getContestants, getScoresForLeaderBoard } from '../../redux/action';

const LeaderboardPage = () => {
  const dispatch = useDispatch();
  const { contestants, loading, leaderboard } = useSelector((state) => state.contestants);

  useEffect(() => {
    if (contestants === null) {
      dispatch(getContestants());
    }

    dispatch(getScoresForLeaderBoard());
  }, [])

  return !loading && (
    <LeaderBoard leaderboard={leaderboard} contestants={contestants} />
  )
}

export default LeaderboardPage;