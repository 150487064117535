import React from 'react';
import PropTypes from 'prop-types';
import styles from './radio.module.scss'
import styled from '@emotion/styled';

const RadioContainer = styled.div`
  border: 1px solid #6D6D6D;
  margin-bottom: 1rem;
  max-width: 35rem;
  min-width: 48%;
  padding: 1rem;
`;

const Radio = ({value, onChange, name, htmlAttributes, children}) => {
  const { className, key, ...otherHtmlAttributes } = htmlAttributes

  return (
    <RadioContainer>
      <div className={className} key={key}>
        <label className={styles.radio}>
          <div className={`${styles.radioContainer} ${otherHtmlAttributes.disabled ? styles.disabled : ''}`}>
            <input
              name={name}
              type="radio"
              value={value}
              onChange={onChange}
              {...otherHtmlAttributes}
            />
            <span className={styles.radioControl}></span>
            <div className={styles.contentContainer}>
              {children}
            </div>
          </div>
        </label>
      </div>
    </RadioContainer>
  )
}

Radio.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  htmlAttributes: PropTypes.object,
  children: PropTypes.node,
};

Radio.defaultProps = {
  htmlAttributes: {}
}

export default Radio