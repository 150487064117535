import React, { useEffect } from 'react'
import "./step4.css"
import InputBox from '../../form/input';
import Radio from '../../form/radio';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../button';
import { setFormData, setStep, getMaiden } from '../../../redux/action';

function Step4() {
  const { formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();

  const { surname, firstname, dateOfBirth, age, gender } = formData

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    if(maiden) {
      dispatch(setFormData({
        surname: maiden.surname,
        firstname: maiden.firstname,
        dateOfBirth: maiden.dateOfBirth,
        age: maiden.age,
        gender: maiden.gender
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 2 of 12 -  PERSONAL INFORMATION</p>

      <form className='step4-form'>
        <p>Contestant Name:</p>
        <br />
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              value={surname}
              htmlAttributes={{
                placeholder: 'Surname',
              }}
              onChange={(value) => dispatch(setFormData({
                surname: value
              }))}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={firstname}
              htmlAttributes={{
                placeholder: 'Firstname',
              }}
              onChange={(value) => dispatch(setFormData({
                firstname: value
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              type="date"
              value={dateOfBirth}
              htmlAttributes={{
                placeholder: 'Date of Birth',
              }}
              onChange={(value) => dispatch(setFormData({
                dateOfBirth: value
              }))}
            />
          </div>

          <div className='step4-inputs-two'>

            <InputBox
              type="number"
              value={age}
              htmlAttributes={{
                placeholder: 'Age',
              }}
              onChange={(value) => dispatch(setFormData({
                age: value
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div
            onChange={event => dispatch(setFormData({
              gender: event.target.value
            }))}
            className='step4-inputs-one'>
            <strong>Gender</strong>
            <Radio
              name='gender'
              value={'Male'}
              htmlAttributes={{
                checked: gender === 'Male'
              }}
            >
              <label className='step4-lab' >Male</label>
            </Radio>

            <Radio
              name='gender'
              value={'Female'}
              htmlAttributes={{
                checked: gender === 'Female'
              }}
            >
              <label className='step4-lab' htmlFor="date">Female</label>
            </Radio>
          </div>
        </div >
      </form >

      <Button
        variant='normal'
        handleClick={() => dispatch(setStep(3)) } 
        disabled={!(firstname && surname && age && gender )}
        className='bg-orange-400 hover:bg-orange-500 text-xl'>
        Next
      </Button>
    </div >
  )
}

export default Step4;