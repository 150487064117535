import UserActionTypes from "./user.types";
import axios from "axios";
import { toast } from "react-toastify";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

export const signInStart = () => ({
  type: UserActionTypes.SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const signUpStart = () => ({
  type: UserActionTypes.SIGN_UP_START
});

export const signUpSuccess = () => ({
  type: UserActionTypes.SIGN_UP_SUCCESS
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error
});

export const updateUserStart = () => ({
  type: UserActionTypes.UPDATE_USER_START
})

export const updateUserSuccess = (user) => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
  payload: user
})

export const updateUserFailure = (error) => ({
  type: UserActionTypes.UPDATE_USER_FAILURE,
  payload: error
})

export const clearError = () => ({
  type: UserActionTypes.CLEAR_ERROR,
});

export const getUserProfileStart = () => ({
  type: UserActionTypes.GET_USER_PROFILE_START
});

export const getUserProfileSuccess = (user) => ({
  type: UserActionTypes.GET_USER_PROFILE_SUCCESS,
  payload: user
})

export const getUserProfileFailure = (error) => ({
  type: UserActionTypes.GET_USER_PROFILE_FAILURE,
  payload: error
})


export const signOut = () => function (dispatch) {
  dispatch(signOutStart());
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  dispatch(signOutSuccess());
  window.location.href = "/";
}

export const signIn = (data) => function (dispatch) {
  dispatch(signInStart());
  axios
    .post("/auth/local", data)
    .then((response) => {
      dispatch(signInSuccess(response.data));
      toast.success("Sign in successful!");
      window.location.href = "/dashboard";
    })
    .catch((error) => {
      toast.error("Sign in failed!");
      dispatch(signInFailure("Something went wrong!"));
    });
}

export const signUp = (data) => function (dispatch) {
  dispatch(signUpStart());
  axios
    .post("/auth/local/register", data)
    .then((response) => {
      const { data } = response
      console.log(data);
      // const message = `Please check your email (${data?.user?.email}) to confirm your account.`;
      // data.message = message
      toast.success('Signup Successful');
      dispatch(signUpSuccess());
      // TODO: Redirect to after sign up page
      window.location.href = '/login';
    }).catch((error) => {
      toast.error("Sign up failed!");
      if(error?.response?.status === 401) {
        console.log(error)
        dispatch(signUpFailure(error?.response?.data?.error));
      } else {
        dispatch(signUpFailure("Something went wrong!"));
      }
    }
  );
}

export const resendEmail = email => {
  axios
    .post("/auth/send-email-confirmation", { email })
    .then((response) => {
      const message = `Please check your email (${email}) to confirm your account.`;
      toast.success(message);
      // console.log(response);
    }).catch((error) => {
      toast.error("Something went wrong!");
    }
  );
}

// export const updateUser = (data, slug='') => function (dispatch) {
//   dispatch(updateUserStart());
//   axios
//     .put("/users", data)
//     .then((response) => {
//       toast.success("Update successful!");
//       dispatch(updateUserSuccess(response.data));
//       slug && dispatch(userProfile(slug))
//     }).catch((error) => {
//       if(error.response.status === 401) {
//         toast.error(error.response?.data?.error);
//         dispatch(updateUserFailure(error.response?.data?.error));
//       } else {
//         toast.error("Something went wrong!");
//         dispatch(updateUserFailure("Something went wrong!"));
//       }
//     }
//   );
// }

// export const userProfile = (slug) => function (dispatch) {
//   dispatch(getUserProfileStart());
//   axios
//     .get(`/users/${slug}`)
//     .then((response) => {
//       dispatch(getUserProfileSuccess(response.data));
//     }).catch((error) => {
//       if(error.response.status === 401) {
//         dispatch(getUserProfileFailure(error.response?.data?.error));
//         toast.error(error.response?.data?.error);
//       } else {
//         toast.error("Something went wrong!");
//         dispatch(getUserProfileFailure("Something went wrong!"));
//       }
//       window.history.back();
//     }
//   );
// }