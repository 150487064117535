import React from 'react'
import "./step3.css"
import Button from '../../button';
import { useDispatch } from 'react-redux';
import { setStep } from '../../../redux/action';

function Step3() {
  const dispatch = useDispatch();

  return (
    <div className="step_three_wrapper">

      <p className="wrap_terms">Step 1 of 12 -  INFORMATION FOR CONTESTANTS</p>

      <div className="warning">
        <h5><strong>WARNING:</strong> Answer all questions in this form fully and honestly. Make sure your answers are legible. You will be automatically disqualified from this pageant if it is discovered that you have deliberately been untruthful or withheld relevant information.</h5>
        <br />

        <p>Please read carefully the section below before completing this registration form. I am aware that <strong>ETHEL WOMEN INITIATIVE. (EWI)</strong> will consider the information I will provide in this document for the purpose of considering me as a contestant in the <strong>FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP)</strong> competition.</p>
        <br />

        <p>I will answer all the questions in this registration form fully, honestly, and accurately and I give my word(s) not to mislead or attempt to mislead the organizers and Organisation in any way as to my identity, qualification, and personal information.</p>
        <br />

        <p>If any of the information I will provide in this registration form is found to be false, the organisation is authorised to exclude me from the <strong>FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) 2022</strong> competition. If I am short-listed, I also agree to authorize the organizers and organization to conduct a background check on me, to verify any of the information I will provide in this registration form.</p>
        <br />
        <p>All decisions by the organisers concerning the selection of contestants are final and no reasons will be given. I agree not to challenge or appeal against these decisions.</p>
        <br />

        <p>I agree that there will be no refund of my Registration form fee if I am not short-listed for the <strong>FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) 2022</strong> competition, and I shall not make a fuss about it with the organisers, or anyone associated with this pageant and organisation.</p>

        <br />
        <p>I agree that I am a legal citizen of the Federal Republic of Nigeria and an indigene within the Niger Delta region (You must be a legal citizen of the federal republic of Nigeria and Niger Delta born, to participate in this competition).</p>

      </div>

      <Button
        variant='normal'
        handleClick={() => dispatch(setStep(2)) } 
        className='bg-orange-400 hover:bg-orange-500 text-xl'>
        Next
      </Button>
    </div>
  )
}

export default Step3;