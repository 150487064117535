import React from 'react'
import { Link } from 'react-router-dom';
import "./leader-board.css"

const getPercentage = (leaderboard, value) => {
  if( leaderboard.length === 0 )  return 0;
  const percentVal =  parseInt((value / leaderboard[0].total) * 100);
  return percentVal < 40 ? `${40}%` : `${percentVal}%`;
}

const getName = (contestandID, contestants) => {
  const contestant = contestants.find(contestant => contestant.id === contestandID);
  return `${contestant?.firstname} ${contestant?.surname}`
}

const getState = (contestandID, contestants) => {
  const contestant = contestants.find(contestant => contestant.id === contestandID);
  return contestant?.state.includes('State') ? contestant?.state : `${contestant?.state} State`
}

const getImage = (contestandID, contestants) => {
  const contestant = contestants.find(contestant => contestant.id === contestandID);
  return contestant?.photo.formats.thumbnail.url;
}

function LeaderBoard({leaderboard=[], contestants}) {

  return leaderboard && (
    <div className='leader'>
      <h1 className='uppercase p-3 text-3xl'>FONDCUP 2022 COMPETITION LEADERBOARD</h1>
    {leaderboard?.map((board, index) => (
      index === 0 ? (
        <div className="leader_head">
          <img className="leader_img" src={getImage(board.contestant_id, contestants)} alt={getName(board.contestant_id, contestants)} />
          <div className="details">
            <p className="leader_details">{getName(board.contestant_id, contestants)}</p>
            <p className="state hidden sm:inline-block">{getState(board.contestant_id, contestants)}</p>
            <p className="leader_details">{board.total} Points</p>
          </div>
        </div>
      ) : (
          <div className="other_details">
            <img className="other_img" src={getImage(board.contestant_id, contestants)} alt={getName(board.contestant_id, contestants)} />
            <div className='details_wrap' style={{ width: getPercentage(leaderboard, board.total) }}>
              <p>{getName(board.contestant_id, contestants)}</p>
              <div className="state hidden sm:inline-block">{getState(board.contestant_id, contestants)}</div>
              <div className="stat">{board.total}</div>
            </div>
          </div>
      ))
    )}
  </div>
  )
}

export default LeaderBoard