import React, { useEffect } from 'react'
import InputBox from '../../form/input';
import Button from '../../button';
import { setFormData, setStep, postContestant, getMaiden } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { CountryAndStates } from '../../form/select/country_state';
import Select from '../../form/select';

const CountrySelectOptions = () => {
  const countryOptions = CountryAndStates.map(country => <option key={country.id} value={country.label}>{country.label}</option>)
  const placeholder = <option disabled="" hidden value="false"></option>

  return (
    [placeholder, ...countryOptions]
  )
}

const StateSelectOptions = () => {
  const NigerianStates = CountryAndStates.find(country => country.label === 'Nigeria')
  const stateOptions = NigerianStates.states.map(state => <option key={state.id} value={state.label}>{state.label}</option>)
  const placeholder = <option disabled="" hidden value="false"></option>

  return (
    [placeholder, ...stateOptions]
  )
}

function Step5() {
  const { formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();
  const { nationality, state, stateOfResidence, lga, lgaOfResident,
    surname, firstname, dateOfBirth, age, gender } = formData;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden) {
      dispatch(setFormData({
        nationality: maiden.nationality,
        state: maiden.state,
        stateOfResidence: maiden.stateOfResidence,
        lga: maiden.lga,
        lgaOfResident: maiden.lgaOfResident
      }))
    }
  }, [])

  const handleSaveProgress = () => {
    const data = {
      notExcoMember: true, 
      isNigerDelta: true, 
      isBetween18And25: true, 
      agreeToTerms: true,
      surname, 
      firstname, 
      dateOfBirth, 
      age, 
      gender,
      nationality, 
      state, 
      stateOfResidence, 
      lga, 
      lgaOfResident,
      currentRegPage: 5
    }
    // console.log(data)
    dispatch(postContestant(data))
  }

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 3 of 12 - ADDITIONAL PERSONAL INFORMATION</p>

      <form className='step4-form'>
        <br />

        <div className='step4-inputs'>
          <div className='step4-inputs-two'>
            <Select
              invalidInput={false}
              options={CountrySelectOptions()}
              placeHolderText={'Nationality'}
              onChange={nationality => dispatch(setFormData({nationality}))}
              selected={nationality}
            />
          </div>
        </div >

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <Select
              invalidInput={false}
              options={StateSelectOptions()}
              placeHolderText={'State of Origin'}
              onChange={state => dispatch(setFormData({state}))}
              selected={state}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={lga}
              htmlAttributes={{
                placeholder: 'LGA of Origin',
              }}
              onChange={(lga) => dispatch(setFormData({lga}))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <Select
              invalidInput={false}
              options={StateSelectOptions()}
              placeHolderText={'State of Residence'}
              onChange={stateOfResidence => dispatch(setFormData({stateOfResidence}))}
              selected={stateOfResidence}
            />
          </div>

          <div className='step4-inputs-two'>
            <InputBox
              value={lgaOfResident}
              htmlAttributes={{
                placeholder: 'LGA of Resident',
              }}
              onChange={(lgaOfResident) => dispatch(setFormData({lgaOfResident}))}
            />
          </div>
        </div>
      </form >

      <div className='flex space-x-2 items-center'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(2)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl'>
          Previous
        </Button>
              
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(4)) } 
          disabled={!(nationality && state && stateOfResidence && lga && lgaOfResident )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'
          >
          Next
        </Button>
      </div>
      {(nationality && state && stateOfResidence && lga && lgaOfResident ) &&
        <span
          onClick={handleSaveProgress} 
          className='text-md text-black mt-4 text-center block underline cursor-pointer'>
          Save Progress
        </span>
      }
    </div >
  )
}

export default Step5;