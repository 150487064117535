import { BounceLoader } from 'react-spinners';

const ScoresBox = ({loading, campScores, publicScores, judgesScores}) => {
  return (
    <div className='flex w-[100%] mx-auto mb-2 items-top justify-center'>
      <div className='bg-white border-r-2 text-center font-bold text-md rounded-tl-md rounded-tr-md'>
        <div className='bg-indigo-800 text-white p-2 rounded-tl-md rounded-tr-md'>
          <h4>{loading ? <BounceLoader size={'25px'} color="white" /> : campScores?.total || 0}</h4>
        </div>
        <div className='p-2'>
          <h4>Camp Score</h4>
        </div>
      </div>

      <div className='bg-white border-r-2 text-center font-bold text-md rounded-tl-md rounded-tr-md'>
        <div className='bg-indigo-800 text-white p-2 rounded-tl-md rounded-tr-md'>
          <h4>{loading ? <BounceLoader size={'25px'} color="white" /> : publicScores?.total || 0}</h4>
        </div>
        <div className='p-2'>
          <h4>Votes</h4>
        </div>
      </div>

      <div className='bg-white text-center font-bold text-md rounded-tl-md rounded-tr-md'>
        <div className='bg-indigo-800 text-white p-2 rounded-tl-md rounded-tr-md'>
          <h4>{loading ? <BounceLoader size={'25px'} color="white" /> : judgesScores?.total || 0}</h4>
        </div>
        <div className='p-2'>
          <h4>Judges</h4>
        </div>
      </div>
    </div>
  )
}

export default ScoresBox;