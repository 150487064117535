import React from 'react';

export default function YouTubeVideo({ videoUrl, name}) {
  const videoId = getVideoIdFromUrl(videoUrl);

  return (
    <iframe
      title={name ? `Miss ${name}` : 'YouTube Video'}
      width="100%"
      height="600px"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

function getVideoIdFromUrl(url) {
  // Regular expression to extract the video id from the URL
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }
  return null;
}