import axios from "axios";
import ContestantActionTypes from "./contestant.type";
import { toast } from "react-toastify";
import { getLocalStorageValue } from "../../utils/constants";

export const setPublicUserData = (data) => ({
  type: ContestantActionTypes.SET_PUBLIC_USER_DATA,
  payload: data,
});

export const getContestants = (year=2022) => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.CONTESTANTS_START });
    const { data } = await axios.get(`/contestants?year=${year}`);
    dispatch({ type: ContestantActionTypes.CONTESTANTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ContestantActionTypes.CONTESTANTS_FAILURE, payload: error });
  }
}

export const getJudgesScores = (id) => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.FETCH_JUDGES_SCORES_START });
    const { data } = await axios.get("/judges-scores");
    const judgeScore = data.find((score) => score.contestant_id === id) || null;
    dispatch({ type: ContestantActionTypes.FETCH_JUDGES_SCORES_SUCCESS, payload: judgeScore });
  } catch (error) {
    dispatch({ type: ContestantActionTypes.FETCH_JUDGES_SCORES_FAILURE, payload: error });
  }
}

export const getCampScores = (id) => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.FETCH_CAMP_SCORES_START });
    const { data } = await axios.get("/camp-scores");
    const campScore = data.find((score) => score.contestant_id === id) || null;
    dispatch({ type: ContestantActionTypes.FETCH_CAMP_SCORES_SUCCESS, payload: campScore });
  } catch (error) {
    dispatch({ type: ContestantActionTypes.FETCH_CAMP_SCORES_FAILURE, payload: error });
  }
}

export const getPublicScores = (id) => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.FETCH_PUBLIC_SCORES_START });
    const { data } = await axios.get("/public-scores");
    const publicScore = data.find((score) => score.contestant_id === id) || null;
    dispatch({ type: ContestantActionTypes.FETCH_PUBLIC_SCORES_SUCCESS, payload: publicScore });
  } catch (error) {
    dispatch({ type: ContestantActionTypes.FETCH_PUBLIC_SCORES_FAILURE, payload: error });
  }
}

export const postPublicScore = data => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.POST_PUBLIC_SCORE_START });
    await axios.post("/public-scores", data);
    dispatch({ type: ContestantActionTypes.POST_PUBLIC_SCORE_SUCCESS });
  } catch (error) {
    dispatch({ type: ContestantActionTypes.POST_PUBLIC_SCORE_FAILURE, payload: error });
  }
}

export const postJudgeScore = data => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.POST_JUDGE_SCORE_START });
    await axios.post("/judges-scores", data, {
      headers: {
        'Authorization': `Bearer ${getLocalStorageValue()}`
      }
      });
    dispatch({ type: ContestantActionTypes.POST_JUDGE_SCORE_SUCCESS });
    toast.success("score submitted successful!");
    //clear fields on success
    dispatch(setFormData({
      maidenSelected: '',
      maidenTask: '',
      maidenScore: ''
    }))
  } catch (error) {
    dispatch({ type: ContestantActionTypes.POST_JUDGE_SCORE_FAILURE, payload: error });
    toast.error("error submitting score");
  }
}

export const postContestant = data => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.POST_CONSTESTANT_START });
    await axios.post("/maiden", data, {
      headers: {
        'Authorization': `Bearer ${getLocalStorageValue()}`
      },
    });
    dispatch({ type: ContestantActionTypes.POST_CONSTESTANT_SUCCESS });
    toast.success("data saved successful!");
  } catch (error) {
    dispatch({ type: ContestantActionTypes.POST_CONSTESTANT_FAILURE, payload: error });
    toast.error("error saving data");
  }
}

export const uploadImage = (file, attrib) => async (dispatch) => {
  const fData = {}
  
  try {
    const { data } = await axios.post("/upload", file, {
      headers: {
        'Authorization': `Bearer ${getLocalStorageValue()}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    fData[`${attrib}`] = {id: data[0].id}
    // console.log('fdata', fData)
    dispatch(setFormData(fData))

    toast.success("File uploaded saved successful!");
  } catch (error) {
    toast.error(error?.response?.statusText ?? "error uploading file");
  }
}

export const getMaiden = () => async (dispatch) => {
  try {
    dispatch({ type: ContestantActionTypes.GET_MAIDEN_START });
    const { data } = await axios.get("/maiden", {
      headers: {
        'Authorization': `Bearer ${getLocalStorageValue()}`
      },
    });
    dispatch({ type: ContestantActionTypes.GET_MAIDEN_SUCCESS, payload: data });
    
  } catch (error) {
    dispatch({ type: ContestantActionTypes.GET_MAIDEN_FAILURE, payload: error });
  }
}

export const getJudge = (userId=null) => async (dispatch) => {
  const url = userId ? `/judges?user=${userId}` : '/judges'
  try {
    dispatch({ type: ContestantActionTypes.GET_JUDGE_START });
    const { data } = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${getLocalStorageValue()}`
      },
    });
    dispatch({ type: ContestantActionTypes.GET_JUDGE_SUCCESS, payload: data });
    
  } catch (error) {
    dispatch({ type: ContestantActionTypes.GET_JUDGE_FAILURE, payload: error });
  }
}

export const getScoresForLeaderBoard = () => async (dispatch) => {
  const scores = [];

  try {
    const { data: campScore } = await axios.get("/camp-scores");
    campScore.forEach((score) => {
      const { contestant_id, total } = score;
      scores.push({ contestant_id, total });
    });
  
    const { data: judgesScore } = await axios.get("/judges-scores");
    judgesScore.forEach((score) => {
      const { contestant_id, total } = score;
      const contestant = scores.find((score) => score.contestant_id === contestant_id);
      if (contestant) {
        contestant.total += total;
      }
    });
  
    const { data: publicScore } = await axios.get("/public-scores");
    publicScore.forEach((score) => {
      const { contestant_id, total } = score;
      const contestant = scores.find((score) => score.contestant_id === contestant_id);
      if (contestant) {
        contestant.total += total;
      }
    });
  
    dispatch({ type: ContestantActionTypes.GET_LEADERBOARD_SUCCESS, payload: scores.sort((a, b) => b.total - a.total) });
    
  } catch (error) {
    dispatch({ type: ContestantActionTypes.GET_LEADERBOARD_FAILURE, payload: error });
  }

};

export const setFormData = (hash) => (dispatch) => {
  dispatch({
    type: 'SET_FORM_DATA',
    hash
  })
}

export const setStep = (newStep) => (dispatch, getState) => {
  const { step } = getState()
  if (step !== newStep) {
    dispatch({
      type: 'SET_STEP',
      step: newStep
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const incrementStep = () => (dispatch, getState) => {
  const { step } = getState()
  setStep(step + 1)(dispatch, getState);
}; 

export const decrementStep = () => (dispatch, getState) => {
  const { step = 1 } = getState()
  setStep(step - 1)(dispatch, getState);
};

