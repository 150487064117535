import React from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import classNames from 'classnames'

const InputBox = ({ value, onChange, htmlAttributes, invalidInput, icon, type="text", isTextarea }) => {
  const handleChange = (newValue) => {
    const pattern = htmlAttributes.pattern
    if (!pattern) {
      onChange(newValue);
      return;
    }

    const regexp = new RegExp(`^${pattern}$`)
    if (regexp.test(newValue)) {
      onChange(newValue);
    }

    if (regexp.test(newValue) && htmlAttributes.isPhoneInput) {
      let phoneNumberLength = newValue.length;

      if (phoneNumberLength > 10) {
        onChange(newValue.replace(/(\d{3})-?(\d{3})-?(\d{5}) ?(\d{1,})/, "$1-$2-$3"));
      } else {
        onChange(newValue.replace(/(\d{3})(\d{3})(\d{5})/, "$1-$2-$3"));
      }
    }
  }
  const { placeholder, className, ...otherHtmlAttributes } = htmlAttributes;
  //we're separating placeholder out from htmlAttributes for compatibility with the label behavior
  return (
    <div className={className}>
      <div className={invalidInput ? styles.floatingLabelError : styles.floatingLabel}>
        {icon && <div className={styles.iconContainer}>{icon}</div>}
        {!isTextarea ? 
          <input
            type={type}
            required
            className={classNames(styles.text, {[styles.withIcon]: !!icon})}
            value={value}
            onChange={(event) => handleChange(event.target.value)}
            id={otherHtmlAttributes.id || placeholder}
            {...otherHtmlAttributes}
          /> :
          <textarea
            className={classNames(styles.text, {[styles.withIcon]: !!icon})}
            style={{
              width: '100%',
              height: '120px',
              border: '1px solid #ccc',
              padding: '1rem'
            }}
            required
            value={value}
            onChange={(event) => handleChange(event.target.value)}
            id={otherHtmlAttributes.id || placeholder}
            {...otherHtmlAttributes}
          />
        }
        {placeholder && <label htmlFor={placeholder}>{placeholder}</label>}
      </div>
    </div>
  )
}

InputBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  htmlAttributes: PropTypes.object,
  invalidInput: PropTypes.bool,
  icon: PropTypes.node
};

InputBox.defaultProps = {
  htmlAttributes: {}
};

export default InputBox