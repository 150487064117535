const ContestantActionTypes = {
  FETCH_CAMP_SCORES_START: "FETCH_CAMP_SCORES_START",
  FETCH_CAMP_SCORES_SUCCESS: "FETCH_CAMP_SCORES_SUCCESS",
  FETCH_CAMP_SCORES_FAILURE: "FETCH_CAMP_SCORES_FAILURE",
  FETCH_JUDGES_SCORES_START: 'FETCH_JUDGES_SCORES_START',
  FETCH_JUDGES_SCORES_SUCCESS: 'FETCH_JUDGES_SCORES_SUCCESS',
  FETCH_JUDGES_SCORES_FAILURE: 'FETCH_JUDGES_SCORES_FAILURE',
  FETCH_PUBLIC_SCORES_START: 'FETCH_PUBLIC_SCORES_START',
  FETCH_PUBLIC_SCORES_SUCCESS: 'FETCH_PUBLIC_SCORES_SUCCESS',
  FETCH_PUBLIC_SCORES_FAILURE: 'FETCH_PUBLIC_SCORES_FAILURE',
  CONTESTANTS_START: 'CONTESTANTS_START',
  CONTESTANTS_SUCCESS: 'CONTESTANTS_SUCCESS',
  CONTESTANTS_FAILURE: 'CONTESTANTS_FAILURE',
  SET_PUBLIC_USER_DATA: 'SET_PUBLIC_USER_DATA',
  POST_PUBLIC_SCORE_START: 'POST_PUBLIC_SCORE_START',
  POST_PUBLIC_SCORE_SUCCESS: 'POST_PUBLIC_SCORE_SUCCESS',
  POST_PUBLIC_SCORE_FAILURE: 'POST_PUBLIC_SCORE_FAILURE',
  GET_LEADERBOARD_SUCCESS: 'GET_LEADERBOARD_SUCCESS',
  GET_LEADERBOARD_FAILURE: 'GET_LEADERBOARD_FAILURE',
  SET_STEP: 'SET_STEP',
  SET_TOC_STEP: 'SET_TOC_STEP',
  SET_FORM_DATA: 'SET_FORM_DATA',
  POST_CONSTESTANT_START: 'POST_CONSTESTANT_START',
  POST_CONSTESTANT_SUCCESS: 'POST_CONSTESTANT_SUCCESS',
  POST_CONSTESTANT_FAILURE: 'POST_CONSTESTANT_FAILURE',
  GET_MAIDEN_START: 'GET_MAIDEN_START',
  GET_MAIDEN_SUCCESS: 'GET_MAIDEN_SUCCESS',
  GET_MAIDEN_FAILURE: 'GET_MAIDEN_FAILURE',
  GET_JUDGE_START: 'GET_JUDGE_START',
  GET_JUDGE_SUCCESS: 'GET_JUDGE_SUCCESS',
  GET_JUDGE_FAILURE: 'GET_JUDGE_FAILURE',
  POST_JUDGE_SCORE_START: 'POST_JUDGE_SCORE_START',
  POST_JUDGE_SCORE_SUCCESS: 'POST_JUDGE_SCORE_SUCCESS',
  POST_JUDGE_SCORE_FAILURE: 'POST_JUDGE_SCORE_FAILURE',
};

export default ContestantActionTypes;