import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from 'react-spinners'
import { tokenExpired } from "../../utils/constants";
import { signOutAction } from "../../redux/action";

function ProtectedRoutes({ component: Component }, props) {
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector(state => state.user.currentUser) 
  const dispatch = useDispatch()

  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Check for expired token as well
    if (currentUser && !tokenExpired()) {
      setLoading(false);
      return;
    }
    setTimeout(() => {
      dispatch(signOutAction())
      navigate("/")
    }, 2000)

  }, [currentUser, navigate]);

  return (
    <>
    {loading ? 
      <div className='w-screen h-screen flex flex-column justify-center items-center bg-gray-400'>
        <h2>Login to access this page | redirecting to home <br /></h2>
        <ScaleLoader color='#1B44FE' height='50px' width='5px' />
      </div> : 
      <Component {...props} />} 
    </>
  )
}

export default ProtectedRoutes;