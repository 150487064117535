import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./home.css"
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import BaseLayout from '../../components/layout';
import Card from '../../components/card';
import { getContestants, getScoresForLeaderBoard } from '../../redux/action';
import Grid from '../../components/grid';
import { ScaleLoader } from 'react-spinners';
import VoteModal from '../../components/modal/vote-modal';

function Home() {
  const dispatch = useDispatch();
  const { contestants, loading, leaderboard } = useSelector((state) => state.contestants);
  const [isOpen, setIsOpen] = useState(false);
  const [contestant, setContestant] = useState(null);

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (contestants === null) {
      dispatch(getContestants());
    } else {
      dispatch(getScoresForLeaderBoard());
    }
  }, [contestants])

  const handleVoteClick = (name, contestantID, username) => {
    setIsOpen(!isOpen)
    setContestant({ name, contestantID, username })
  }

  return (
    <BaseLayout>
      <div>
        <div className="h-[45vh] w-full overflow-hidden object-contain bg-[url('https://res.cloudinary.com/dkeumloss/image/upload/v1670233307/blog_banner_2022_a53ba3659d.jpg')] bg-center flex justify-center items-center">

          <div className=''>
            <h2 className='const_title pt-10'>2022 Maidens</h2>
            <div className="const_sub_title">
              <a href="/#">Face of Niger Delta Cultural Pageant - FONDCUP.NG</a>
              <p><DoubleArrowIcon />  Maidens</p>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div className='max-w-[1200px] mx-auto p-6 sm:p-10 overflow-hidden'>
          {loading ? <div className='text-center'><ScaleLoader color='orange' height='50px' width='5px' /></div> :
            <Grid selectedSize={'3'} selectedSizeTablet={'2'}>
              {
                contestants && contestants.map(contestant => {
                  const totalScore = (contestant && leaderboard) ? leaderboard.find((maiden) => maiden.contestant_id === contestant.id)?.total : '';

                  return (
                  <div key={contestant.id}>
                    <Card
                      onVoteClick={() => handleVoteClick(`${contestant.firstname} ${contestant.surname}`, contestant.id, contestant.username)}
                      setIsOpen={setIsOpen}
                      state={contestant?.state?.includes('State') ? contestant?.state : `${contestant?.state} State`}
                      image={contestant.photo.url}
                      name={`${contestant.firstname} ${contestant.surname}`}
                      username={contestant.username}
                      totalScore={totalScore}
                    />
                  </div>
                  )
                })
              }
            </Grid>
          }
        </div>
      </div>
      {isOpen && <VoteModal setIsOpen={setIsOpen} onClose={onClose} contestant={contestant} />}
    </BaseLayout>
  )
}

export default Home;