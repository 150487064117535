import React from 'react'
import "./step2.css"
import Button from '../../button';
import { useDispatch } from 'react-redux';
import { setStep } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';


function Step2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const EligibilityContent = [
    {
      id: 1,
      content: 'You must be a bona-fide Nigerian citizen from the Niger Delta Region, namely, Akwa Ibom, Abia, Bayelsa, Cross River, Delta, Edo, Ondo, Imo, Rivers states respectively.'
    },
    {
      id: 2,
      content: 'You must be at least 18 years of age and not more than 28 years old.'
    },
    {
      id: 3,
      content: 'You must be in excellent physical and mental health.'
    },
    {
      id: 4,
      content: 'You must be willing and able to travel to any part of the country FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) and competition camping will be held for a period of 14 days, within the Niger Delta region'
    },
    {
      id: 5,
      content: 'You must be willing and able to cover all your travel expenses to and from the location of “FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP)” where all contestants will be resident throughout the duration of the competition.'
    },
    {
      id: 6,
      content: 'You must be willing and able to fully participate for the duration of 14 days camping, based on the dates set by the organizers.'
    },
    {
      id: 7,
      content: 'You must be willing and able to peacefully coexist with the other maidens who will be your competitors in “FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP)” during the duration of the competition.'
    },
    {
      id: 8,
      content: 'You must be a Graduate, an undergraduate or at least a school cert holder of a recognized Higher Institution or secondary school of Learning; and must be willing to show originals of your birth certificate, academic certificates, and professional certificates (if any).'
    }
  ]

  return (
    <div className="step_two_wrapper">
      <span
       className='text-2xl cursor-pointer'
       onClick={() => dispatch({
        type: 'SET_TOC_STEP',
        step: 1
      }) } 
      >←</span>
      <h4>ELIGIBILITY</h4>

      { EligibilityContent.map(elig => (
        <React.Fragment key={elig.id}>
          <div className='step2_paragraphs'>
            <span>»</span>
            <p>{elig.content}</p>
          </div>
          {EligibilityContent.length !== elig.id && <hr />}
        </React.Fragment>
      ))
      }

      <Button
        variant='normal'
        handleClick={() => navigate('/signup') } 
        className='bg-orange-400 hover:bg-orange-500 text-xl'>
        Sign Up To Continue Registration
      </Button>

    </div>
  )
}

export default Step2;