import UserActionTypes from "./user.types";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

const INITIAL_STATE = {
  currentUser: null,
  error: null,
  loading: false,
  userProfile: null,
  token: localStorage.getItem(LOCAL_STORAGE_KEY),
  message: null,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_START:
    case UserActionTypes.SIGN_UP_START:
    case UserActionTypes.UPDATE_USER_START:
    case UserActionTypes.GET_USER_PROFILE_START:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        token: localStorage.setItem(LOCAL_STORAGE_KEY, action.payload.jwt),
        error: null,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentUser: null,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // currentUser: action.payload.user,
        // token: localStorage.setItem(LOCAL_STORAGE_KEY, action.payload.jwt),
        // message: action.payload.message,
      };
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentUser: null,
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: null,
      };
    case UserActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        token: null,
        error: null,
      }
    case UserActionTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UserActionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case UserActionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
      };
    case UserActionTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        userProfile: null,
      };
    default:
      return state;
  }
};

export default userReducer;