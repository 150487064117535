import React, { useEffect } from 'react'
import "./step12.css"
import Radio from '../../form/radio';
import Button from '../../button';
import { getMaiden, setFormData, setStep } from '../../../redux/action';
import { useSelector } from 'react-redux';

function Step12({ formData, dispatch }) {
  const { maiden } = useSelector(state => state.contestants);

  const { arrestedBefore, treatedOfSeriousIllness, haveTerminalIllness,
    onAnyMedication, haveAnyAllergies, specialNeeds } = formData;

    useEffect(() => {
      if (maiden === null) {
        dispatch(getMaiden())
      }
      
      if(maiden) {
        dispatch(setFormData({
          arrestedBefore: maiden.arrestedBefore,
          treatedOfSeriousIllness: maiden.treatedOfSeriousIllness,
          haveTerminalIllness: maiden.haveTerminalIllness,
          onAnyMedication: maiden.onAnyMedication,
          haveAnyAllergies: maiden.haveAnyAllergies,
          specialNeeds: maiden.specialNeeds
        }))
      }
    }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 10 of 12 -  SECURITY QUESTIONS</p>

      <form className='step4-form'>
        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Have you ever been arrested or had a restraining order placed against you?</strong>
            <Radio
              name='arrested'
              value={'true'}
              onChange={() => dispatch(setFormData({
                arrestedBefore: true
              }))}
              htmlAttributes={{
                checked: arrestedBefore
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='arrested'
              value={'false'}
              onChange={() => dispatch(setFormData({
                arrestedBefore: false
              }))}
              htmlAttributes={{
                checked: arrestedBefore === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Have you ever been treated for any serious physical or mental illness?</strong>
            <Radio
              name='illness'
              value={'true'}
              onChange={() => dispatch(setFormData({
                treatedOfSeriousIllness: true
              }))}
              htmlAttributes={{
                checked: treatedOfSeriousIllness
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='illness'
              value={'false'}
              onChange={() => dispatch(setFormData({
                treatedOfSeriousIllness: false
              }))}
              htmlAttributes={{
                checked: treatedOfSeriousIllness === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Do you have any terminal illness?</strong>
            <Radio
              name='terminal'
              value={'true'}
              onChange={() => dispatch(setFormData({
                haveTerminalIllness: true
              }))}
              htmlAttributes={{
                checked: haveTerminalIllness
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='terminal'
              value={'false'}
              onChange={() => dispatch(setFormData({
                haveTerminalIllness: false
              }))}
              htmlAttributes={{
                checked: haveTerminalIllness === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Are you on any prescribed medication that you take on a regular basis?</strong>
            <Radio
              name='regularMedication'
              value={'true'}
              onChange={() => dispatch(setFormData({
                onAnyMedication: true
              }))}
              htmlAttributes={{
                checked: onAnyMedication
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='regularMedication'
              value={'false'}
              onChange={() => dispatch(setFormData({
                onAnyMedication: false
              }))}
              htmlAttributes={{
                checked: onAnyMedication === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>
        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Do you have any allergies or medical condition?</strong>
            <Radio
              name='allergies'
              value={'true'}
              onChange={() => dispatch(setFormData({
                haveAnyAllergies: true
              }))}
              htmlAttributes={{
                checked: haveAnyAllergies
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='allergies'
              value={'false'}
              onChange={() => dispatch(setFormData({
                haveAnyAllergies: false
              }))}
              htmlAttributes={{
                checked: haveAnyAllergies === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Do you have any special needs or fears that we should know about?</strong>
            <Radio
              name='specialNeeds'
              value={'true'}
              onChange={() => dispatch(setFormData({
                specialNeeds: true
              }))}
              htmlAttributes={{
                checked: specialNeeds
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='specialNeeds'
              value={'false'}
              onChange={() => dispatch(setFormData({
                specialNeeds: false
              }))}
              htmlAttributes={{
                checked: specialNeeds === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>
      </form >

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(9)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(11)) } 
          disabled={![arrestedBefore, treatedOfSeriousIllness, haveTerminalIllness, onAnyMedication, haveAnyAllergies, specialNeeds].every(val => val === true || val === false)}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>

    </div >)
}

export default Step12;