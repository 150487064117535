
export const URL=process.env.API_PROD_URL;

export const LOCAL_STORAGE_KEY='fondcup_!@#$%^&*()_+token';
const TOKEN_EXPIRY_TIME = 86400; // expiry time in seconds (1 day)

export const getLocalStorageValue = () => {
  if(typeof window !== 'undefined') {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }
}



export const tokenExpired = () => {
  const payload = JSON.parse(window.atob(getLocalStorageValue().split('.')[1]));
  const exp = payload.exp;
  const currentTime = new Date();
  let currentTimeInMilliseconds = currentTime.getTime();
  let expiryInMilliseconds = exp * 1000; // convert the expiry time from seconds to milliseconds
  let tokenExpiryInMilliseconds = TOKEN_EXPIRY_TIME * 1000; // convert the token expiry time from seconds to milliseconds
  return currentTimeInMilliseconds > expiryInMilliseconds + tokenExpiryInMilliseconds; // return true if the current time is greater than the expiry time + token expiry time
}

export function transformImageURL(url, size=1000) {
  const baseURL = `https://res.cloudinary.com/dkeumloss/image/upload`
  const transformation = `/h_${size},w_auto`
  const cloudId = url.split('/').pop()

  return `${baseURL}${transformation}/${cloudId}`
}