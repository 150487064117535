import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss'
import styled from '@emotion/styled';

const RadioContainer = styled.div`
  border: 1px solid #6D6D6D;
  margin-bottom: 1rem;
  max-width: 35rem;
  min-width: 48%;
  padding: 1rem;
`;

const CheckBox = ({ text, children, onChange, checked, htmlAttributes }) => {
  return (
    <RadioContainer>
      <div className={styles.checkboxWrapper}>
        <label className={styles.checkbox}>
          <input
            type="checkbox"
            onChange={onChange}
            checked={checked}
            {...htmlAttributes}
          />
          <span></span>
        </label>
        {!!text ? <p className={styles.checkboxText}>{text}</p> : <div className={styles.checkboxText}>{children}</div>}
      </div>
    </RadioContainer>
  )
}

CheckBox.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

CheckBox.defaultProps = {
  checked: false,
  htmlAttributes: {},
};

export default CheckBox