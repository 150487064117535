import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./step1.css"
import CheckBox from '../../form/checkbox';
import { setFormData } from '../../../redux/action';
import Button from '../../../components/button'

function Step1() {
  const { formData } = useSelector(state => state.contestants);
  const dispatch = useDispatch();
  let { notExcoMember, isNigerDelta, isBetween18And25, agreeToTerms } = formData


  return (
    <div className="step_one_wrapper">

      <p className="wrap_terms">TERMS AND CONDITIONS</p>

      <h2>TERMS AND CONDITIONS</h2>
      <p>You must be a bona-fide Nigerian citizen from the Niger Delta Region, namely, Akwa Ibom, Abia, Bayelsa, Cross River, Delta, Edo, Ondo, Imo, Rivers states respectively.</p>

      <p><i>(Please Read the following as your Acknowledgement of the Terms and Conditions of this pageant)</i></p>

      <p><strong>I hereby acknowledge and confirm that:</strong></p>
      <p>I have read, met and I agree to be bound by the eligibility requirements for “FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) COMPETITION” as set out in this document.
</p>

      <p>If any of the information I gave in my registration form is at any point found to be false, I understand and agree that this will be sufficient grounds for my disqualification from the competition screening/selection process and/or from the competition and campaign itself, even if I had previously been selected.</p>

      <p>All decisions by the Producers and organizers regarding the screening/selection of the contestants are final and not subject to appeal or challenge.</p>

      <p>I agree that my voice, actions, and likeness may be recorded as a part of the registration and participant screening/selection process and I understand that the Producers and organizers of FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) may decide, to use such materials during the competition and on FACE OF NIGER DELTA CULTURAL PAGEANT (FONDCUP) social media platforms and website.</p>

      <p>The Producers and organizers are not obligated to return any materials submitted by me as part of the participant screening/selection process, whether or not I am selected to be a contestant. The Producers may but are in no way obligated to use any such materials in, and in connection with, the programme.</p>

      <p>If I am short-listed by the Producers through votes by the public and/or screening process; I agree to undergo psychological and medical screening, the full details of which will be explained to me in advance.</p>

      <p>I agree to authorize the organizers to conduct background checks to verify any information supplied by me during registration.</p>

      <p>If I am short-listed, I hereby assign to ETHEL WOMEN INITIATIVE, the Right to use my photographs, likeness, profile, voice, sound effect, and other materials for the purpose of the campaign, competition and/or any related publicity and marketing whether or not I emerge the winner of the competition, whether for the season I applied for, or for the subsequent seasons of the campaign and competition that I am not part of.</p>

      <form>
        <CheckBox
          onChange={() => dispatch(setFormData({
            notExcoMember: true
          }))}
          checked={notExcoMember}>
          I am not an Exco or member of ETHEL WOMEN INITIATIVE and related entity.
        </CheckBox>


        <CheckBox
          onChange={() => dispatch(setFormData({
            isNigerDelta: true
          }))}
          checked={isNigerDelta}
        >
          I am a bona-fide (legal) Niger Deltan and Nigerian citizen.
        </CheckBox>


        <CheckBox
          onChange={() => dispatch(setFormData({
            isBetween18And25: true
          }))}
          checked={isBetween18And25}>
          I am between the 18 - 25 years age bracket.
        </CheckBox>

        <CheckBox
          onChange={() => dispatch(setFormData({
            agreeToTerms: true
          }))}
          checked={agreeToTerms}>
          I agree to the Terms and Conditions above
        </CheckBox>
      </form>

      <Button
        variant='normal'
        handleClick={() => dispatch({
          type: 'SET_TOC_STEP',
          step: 2
        }) } 
        disabled={!(notExcoMember && isNigerDelta && isBetween18And25 && agreeToTerms )}
        className='bg-orange-400 hover:bg-orange-500 text-xl'>
        Next
      </Button>

    </div>
  )
}

export default Step1;