import React from "react";
import { useSelector } from "react-redux";
import BaseLayout from "../../components/layout"
import Step1 from "../../components/registration/step1";
import Step2 from "../../components/registration/step2";


const TermsAndCondition = () => {
  const { tocStep } = useSelector(state => state.contestants);

  const formStep = (stepNum) => {
    return (
      {
        1: <Step1 />,
        2: <Step2 />,
      }[stepNum]
    )
  }

  return (
    <BaseLayout>
      <div className="pt-[100px] sm:pt-[90px] md:pt-[130px] pl-5 pr-5 sm:pl-14 sm:pr-14 pb-14 ">
        <h1 className="text-center font-bold">FONDCUP REGISTRATION</h1>
        {formStep(tocStep)}
      </div>
    </BaseLayout>
  )
}

export default TermsAndCondition