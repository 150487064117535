import NavBar from '../nav';
import Footer from '../footer';

export default function BaseLayout({ children }) {

  return (
    <div className="h-[calc(100vh-150px)] w-full">
      <NavBar />
        {children}
      <Footer />
    </div>
  )
}