import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BeatLoader } from 'react-spinners';
import BaseLayout from '../../components/layout';
import "./login.css";
import logoImg from '../../images/Login-logo.png';
import { signAction } from '../../redux/action';
import { Link } from 'react-router-dom';


const initialValues = {
  email: "",
  password: "",
};
const validateSchema = Yup.object({
  email: Yup.string().email("Please enter a valid email").required("Required"),
  password: Yup.string().min(6, "Password too short").required("Required")
});

export default function Login() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  return (
    <BaseLayout>
      <div className="pt-[100px] sm:pt-[90px] md:pt-[130px] pl-5 pr-5 sm:pl-14 sm:pr-14 pb-14 h-vh sm:h-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            const data = {
              identifier: values.email,
              password: values.password,
            }
            dispatch(signAction(data));
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <div className="login_wrapper">
                <Form className="form">
                  <img src={logoImg} alt="Fondcup Logo" />
                  <div className="form-item">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="email"
                      className={errors.email && touched.email ?
                        "input-error" : null}
                    />
                    <ErrorMessage name="email" component="span" className="error" />
                  </div>

                  <div className="form-item">
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="password"
                      className={errors.password && touched.password ?
                        "input-error" : null}
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>

                  <button
                    type="submit"
                    className={"login-btn"}
                    disabled={!(dirty && isValid)}
                  >
                    {loading ? 
                      <>
                        Loading <BeatLoader size={10} color={"#fff"} />
                      </> : 
                      "Go to Dashboard"
                    }
                  </button>
                  <div className="reg">
                    <p>Don't have an account?<Link to='/signup'><a href="/#"> Signup</a></Link> </p>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </BaseLayout>
  );
};