import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import styles from './select.module.scss'



export const NO_SELECTION = 'false';

export const SelectOption = ({ value, text }) => {
  if (!text) text = _.capitalize(value);

  return <option value={value}>{text}</option>
};

const Select = ({ selected, onChange, options, htmlAttributes, invalidInput, placeHolderText }) => {
  const { className, disabled, ...otherHtmlAttributes } = htmlAttributes
  var placeholder = placeHolderText || htmlAttributes.placeholder
  const hasBlankOption = _.some(options, option => option.props.value == NO_SELECTION)

  return (
    <div className={className}>
      <div className={invalidInput ? styles.selectError : styles.select} disabled={disabled}>
        <select
          value={selected}
          onChange={e => onChange(e.target.value)}
          data-selected={selected}
          id={otherHtmlAttributes.id || placeholder}
          required
          disabled={disabled}
          {...otherHtmlAttributes}
        >
          {!hasBlankOption && <option disabled value={NO_SELECTION}> </option>}
          {options.map((item, key) => React.cloneElement(item, {key}))}
        </select>
        {placeholder && <label htmlFor={placeholder}>{placeholder}</label>}
      </div>
    </div>
  )
}

Select.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.node,
  htmlAttributes: PropTypes.object,
  invalidInput: PropTypes.bool,
};

Select.defaultProps = {
  selected: NO_SELECTION,
  htmlAttributes: {}
};

export default Select

