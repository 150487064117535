import React from 'react';
import {
  BrowserRouter, Routes, Route
} from 'react-router-dom';

import Home from './pages/home';
import Login from './pages/login';
import RegistrationForm from './pages/registration';
import Dashboard from './pages/dashboard';
import ProtectedRoutes from './components/protected-routes';
import Maiden from './pages/maiden';
import SignUp from './pages/signup';
import LeaderboardPage from './pages/leaderboard';
import TermsAndCondition from './pages/toc';

const MyRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<ProtectedRoutes component={RegistrationForm} />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path='/dashboard' element={<ProtectedRoutes component={Dashboard} />} />
      <Route exact path="/maiden/:username" element={<Maiden />} />
      <Route exact path="/leaderboard" element={<LeaderboardPage />} />
      <Route exact path="/terms-and-condition" element={<TermsAndCondition />} />

    </Routes>
  </BrowserRouter>
);

export default MyRoutes;