import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom';
import "./maiden.css"
import BaseLayout from '../../components/layout';
import { BounceLoader } from 'react-spinners';
import { LinkedIn, Facebook, Instagram, Twitter } from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch, useSelector } from 'react-redux';
import contestantPlaceholder from '../../images/contestant-placeholder.jpg';
import { getJudgesScores, getCampScores, getPublicScores, getContestants } from '../../redux/action';
import Button from "../../components/button";
import VoteModal from '../../components/modal/vote-modal';
import YouTubeVideo from '../../components/video';
import { transformImageURL } from '../../utils/constants';
import ScoresBox from '../../components/scores-box';

// TODO: Move this to a utils file
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// TODO: Move this to a utils file
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Maiden() {
  const { username } = useParams();
  const { contestants, judgesScores, campScores, publicScores, loading } = useSelector((state) => state.contestants);
  const contestant = contestants ? contestants.find(contestant => contestant.username === username) : null;
  const [isOpen, setIsOpen] = useState(false);
  const [screenType, setScreenType] = useState('desktop');

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    // window.scrollTo(0, 200);

    if (contestants === null) {
      dispatch(getContestants());
    }

    if (contestant) {
      dispatch(getJudgesScores(contestant.id));
      dispatch(getCampScores(contestant.id));
      dispatch(getPublicScores(contestant.id));
    }
  }, [contestant, contestants]);

  useEffect(() => {
    if(width < 768) {
      setScreenType('mobile')
    } else {
      setScreenType('desktop')
    }
  }, [width])

  return (
    <BaseLayout>
      <>
        {contestant ?
          <>
            <div className="profile_container">
              <div className="profile_header overflow-hidden relative">
              <img src={ (screenType === 'desktop' ? contestant.coverImageDesktop : contestant.coverImageMobile) 
                   || 'https://res.cloudinary.com/dkeumloss/image/upload/v1670233307/blog_banner_2022_a53ba3659d.jpg'
                } width={'100%'} alt="" />
              </div>
            </div>

            <div className="profile_hero">
              <div className="profile_hero_details overflow-hidden max-w-[600px] min-w-[250px]">

                <div className='overflow-hidden'>
                  <img src={transformImageURL(contestant.photo.url, 1500) || contestantPlaceholder} className="w-full h-auto object-fit" alt="" />
                </div>
                <div className="profile_details_icons absolute left-2 top-2">
                  {contestant.social_media_links.map(link => {
                    const url = link.url.includes('http') ? link.url : `https://${link.url}`;

                    if (link.title === 'Facebook') {
                      return <a target={'_blank'} rel="noopener noreferrer" key={link.id} href={url}><Facebook fontSize="large" style={{ color: "white" }} /></a>
                    }

                    if (link.title === 'Instagram') {
                      return <a target={'_blank'} rel="noopener noreferrer" key={link.id} href={url}><Instagram fontSize="large" style={{ color: "white" }} /></a>
                    }

                    if (link.title === 'Twitter') {
                      return <a target={'_blank'} rel="noopener noreferrer" key={link.id} href={url}><Twitter fontSize="large" style={{ color: "white" }} /></a>
                    }

                    if (link.title === 'LinkedIn') {
                      return <a target={'_blank'} rel="noopener noreferrer" key={link.id} href={url}><LinkedIn fontSize="large" style={{ color: "white" }} /></a>
                    }

                    return null;

                  })}
                </div>
                <div className="profile_details py-4">

                  <h2>{`${contestant.firstname} ${contestant.surname}`}</h2>
                  <h4> <LocationOnOutlinedIcon fontSize="small" />{contestant?.state.includes('State') ? contestant?.state : `${contestant?.state} State`}</h4>

                  <ScoresBox loading={loading} campScores={campScores} publicScores={publicScores} judgesScores={judgesScores} />

                  {/* <div className='mt-4 text-center'>
                    <Button
                      onClick={() => setIsOpen(!isOpen)}
                      className="mb-4"
                      variant='normal'
                    >
                      Vote
                    </Button>
                  </div> */}
                </div>
              </div>

              <div className="profile_infos">
                <div className="profile_infos_details">
                  <h3>PROFILE INFORMATION</h3>
                  <ul >
                    {
                      contestant.statistics.map(stat => (
                        <li key={stat.id}><strong>{stat.title}:</strong> {stat.unit}</li>
                      ))
                    }
                  </ul>

                </div>
                {/* className='text-lg font-bold mt-4 mb-4' */}
                <h2>HOW THE JUDGES VOTED</h2>
                {false ? (
                  <>
                    <div className='flex items-center mb-6'>
                      <div className='w-[60px] h-[60px] rounded-full overflow-hidden mr-4'>
                        <img src="https://fondcup.ng/wp-content/uploads/2021/10/Judge-GeorgeH-100x100.jpeg" alt="" />
                      </div>
                      <h4 className='text-lg font-bold mr-4'>George H. Ashiru</h4>
                      <h4 className='text-lg font-bold'>0 votes</h4>
                    </div>
                    <hr />

                    <div className='flex items-center mt-4 mb-4'>
                      <div className='w-[60px] h-[60px] rounded-full overflow-hidden mr-4'>
                        <img src="https://fondcup.ng/wp-content/uploads/2021/10/Judge-KikiO.jpeg" alt="" />
                      </div>
                      <h4 className='text-lg font-bold mr-4'>Kiki Omeili Omeili </h4>
                      <h4 className='text-lg font-bold'>0 votes  </h4>
                    </div>
                    <hr />

                    <div className='flex items-center  mt-4 mb-4'>
                      <div className='w-[60px] h-[60px] rounded-full overflow-hidden mr-4'>
                        <img src="https://fondcup.ng/wp-content/uploads/2021/10/Judge-LarryK.jpeg" alt="" />
                      </div>
                      <h4 className='text-lg font-bold mr-4'>Larry Kay Ojomo  </h4>
                      <h4 className='text-lg font-bold'>0 votes    </h4>
                    </div>
                  </>
                ) : (
                  <>
                    <p>Votes count will be made available soon</p>
                  </>
                )}
              </div>
            </div>

            {contestant.videoUrl && (
              <div className='max-w-[1200px] overflow-hidden mx-auto py-6'>
                <YouTubeVideo videoUrl={contestant.videoUrl} name={`${contestant.firstname} ${contestant.surname}`} />
              </div>
            )}
          </> : <BounceLoader size={'25px'} color="indigo" />
        }
      </>

      {isOpen && <VoteModal setIsOpen={setIsOpen} onClose={onClose} contestant={{
        name: `${contestant.firstname} ${contestant.surname}`,
        contestantID: contestant.id,
        username: contestant.username
      }} />}
    </BaseLayout >
  )
}

export default Maiden;