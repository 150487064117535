import React from 'react'
import "./footer.css"

function Footer() {
  return (
    <footer>
      <div className="footer_items">
        <a href="/#" ><img src="https://fondcup.ng/wp-content/uploads/2021/10/fondcup-logo-light-300x93.png" width="80%" alt="" /></a>
      </div>

      <div className="copy_right">
        <p>© 2022 - FONDCUP.NG All Rights Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer