import React, { useEffect } from 'react'
import "./step10.css"
import Radio from '../../form/radio';
import InputBox from '../../form/input';
import Button from '../../button';
import { setFormData, setStep, postContestant, getMaiden } from '../../../redux/action';
import { useSelector } from 'react-redux';

function Step10({ formData, dispatch }) {
  const { maiden } = useSelector(state => state.contestants);

  const { areYouAModel, participateInPageantBefore, moneyImportant,
    kindOfPeopleIntimidateYou, howCompetitiveAreYou, workWellWithTeam,
    homeAddress, phoneNumber, emailAdrress, notExcoMember, isNigerDelta, isBetween18And25, 
    agreeToTerms, surname, firstname, dateOfBirth, age, gender, nationality, state, stateOfResidence, 
    lga, lgaOfResident, statistics, education, referee  } = formData;

  const handleSaveProgress = () => {
    const data = {
      areYouAModel, participateInPageantBefore, moneyImportant,
      kindOfPeopleIntimidateYou, howCompetitiveAreYou, workWellWithTeam,
      homeAddress, phoneNumber, emailAdrress, notExcoMember, isNigerDelta, isBetween18And25, 
      agreeToTerms, surname, firstname, dateOfBirth, age, gender, nationality, state, stateOfResidence, 
      lga, lgaOfResident, statistics, education: [education], referee: [referee], currentRegPage: 8
    }
    dispatch(postContestant(data))
  }

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden) {
      dispatch(setFormData({
        areYouAModel: maiden.areYouAModel,
        participateInPageantBefore: maiden.participateInPageantBefore,
        moneyImportant: maiden.moneyImportant,
        kindOfPeopleIntimidateYou: maiden.kindOfPeopleIntimidateYou,
        howCompetitiveAreYou: maiden.howCompetitiveAreYou,
        workWellWithTeam: maiden.workWellWithTeam
      }))
    }
  }, [])

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 8 of 12 -  OTHER INFORMATION</p>

      <form className='step4-form'>

        <div className='step4-inputs step-9-top'>
          <div className='step4-inputs-one'>
            <strong>Are you a Model?</strong>
            <Radio
              onChange={() => dispatch(setFormData({
                areYouAModel: true
              }))}
              name='model'
              htmlAttributes={{
                checked: areYouAModel
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>

            <Radio
              onChange={() => dispatch(setFormData({
                areYouAModel: false
              }))}
              name='model'
              htmlAttributes={{
                checked: areYouAModel === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>

          <div className='step4-inputs-one'>
            <strong>Have you ever participated in a Pageant?</strong>
            <Radio
              name='pageant'
              onChange={() => dispatch(setFormData({
                participateInPageantBefore: true
              }))}
              htmlAttributes={{
                checked: participateInPageantBefore
              }}>
              <label className='step4-lab' htmlFor="radio">
                Yes
              </label>
            </Radio>
            <Radio
              name='pageant'
              onChange={() => dispatch(setFormData({
                participateInPageantBefore: false
              }))}
              htmlAttributes={{
                checked: participateInPageantBefore === false
              }}>
              <label className='step4-lab' htmlFor="radio">
                No
              </label>
            </Radio>
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              htmlAttributes={{
                placeholder: 'Importance Of Money To you',
              }}
              value={moneyImportant}
              onChange={moneyImportant => dispatch(setFormData({moneyImportant}))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              isTextarea
              value={kindOfPeopleIntimidateYou}
              onChange={kindOfPeopleIntimidateYou => dispatch(setFormData({
                kindOfPeopleIntimidateYou
              }))}
              htmlAttributes={{
                placeholder: 'What kind of people intimidate you?',
              }}
              
             />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              isTextarea
              value={howCompetitiveAreYou}
              onChange={howCompetitiveAreYou => dispatch(setFormData({howCompetitiveAreYou}))}
              htmlAttributes={{
                placeholder: 'How Competitive are you and how do you react when you lose?',
              }}
                />
          </div>
        </div>

        <div className='step4-inputs-one'>
          <InputBox
            htmlAttributes={{
              placeholder: 'How well do you work in a team?',
            }}
            value={workWellWithTeam}
            onChange={workWellWithTeam => dispatch(setFormData({workWellWithTeam}))}
          />
        </div>
      </form>

      <div className='flex justify-between'>
        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(7)) } 
          className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
          Previous
        </Button>

        <Button
          variant='normal'
          handleClick={() => dispatch(setStep(9)) } 
          disabled={!((participateInPageantBefore === true || participateInPageantBefore === false) && 
            (areYouAModel === true || areYouAModel === false) && workWellWithTeam && 
            howCompetitiveAreYou && kindOfPeopleIntimidateYou && moneyImportant )}
          className='bg-orange-400 hover:bg-orange-500 text-xl'>
          Next
        </Button>
      </div>
      {((participateInPageantBefore === true || participateInPageantBefore === false) && 
            (areYouAModel === true || areYouAModel === false) && workWellWithTeam && 
            howCompetitiveAreYou && kindOfPeopleIntimidateYou && moneyImportant ) &&
        <span
          onClick={handleSaveProgress} 
          className='text-md text-black mt-4 text-center block underline cursor-pointer'>
          Save Progress
        </span>
      }
    </div>
  )
}
export default Step10;