import React, { useEffect } from 'react'
import InputBox from '../../form/input';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData, setStep, getMaiden } from '../../../redux/action';
import Button from '../../button';

function Step6() {
  const { formData, maiden } = useSelector(state => state.contestants);
  const dispatch = useDispatch();

  const { statistics } = formData;

  useEffect(() => {
    if (maiden === null) {
      dispatch(getMaiden())
    }
    
    if(maiden && maiden.statistics.length > 0) {
      dispatch(setFormData({
        statistics: maiden.statistics,
      }))
    }
  }, [])

  const getStatistic = title => {
    const stat = statistics.find(stat => stat.title === title)
    return stat.unit
  }

  const setStatistics = (title, unit) => {
    const stat = statistics.find(stat => stat.title === title)
    stat.unit = unit
    return statistics
  }

  const isEveryUnitNotEmpty = statistics.every(stat => stat.unit !== '');

  return (
    <div className="step_four_wrapper">

      <p className="wrap_terms">Step 4 of 12 -  BODY STATISTICS</p>

      <form className='step4-form'>
        <p>Contestant Statistics:</p>
        <br />
        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              type="number"
              value={getStatistic('Weight')}
              htmlAttributes={{
                placeholder: 'Weight (kg)',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Weight', value)
              }))}
            />
          </div>
          <div className='step4-inputs-two'>
            <InputBox
              type="number"
              value={getStatistic('Height')}
              htmlAttributes={{
                placeholder: 'Height (ft)',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Height', value)
              }))}
            />
          </div>
        </div>


        <div className='step4-inputs'>
          <div className='step4-inputs-one'>
            <InputBox
              type="number"
              value={getStatistic('Burst')}
              htmlAttributes={{
                placeholder: 'Bust Size',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Burst', value)
              }))}
            />
          </div>
          <div className='step4-inputs-two'>
            <InputBox
              type="number"
              value={getStatistic('Waist')}
              htmlAttributes={{
                placeholder: 'Waist Size',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Waist', value)
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-two'>
            <InputBox
              type="number"
              value={getStatistic('Hip')}
              htmlAttributes={{
                placeholder: 'Hip Size',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Hip', value)
              }))}
            />
          </div>
          <div className='step4-inputs-two'>
            <InputBox
              value={getStatistic('Eye Color')}
              htmlAttributes={{
                placeholder: 'Eye Color',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Eye Color', value)
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-two'>
            <InputBox
              value={getStatistic('Hair Color')}
              htmlAttributes={{
                placeholder: 'Hair Color',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Hair Color', value)
              }))}
            />
          </div>
          <div className='step4-inputs-two'>
            <InputBox
              type="number"
              value={getStatistic('Shoe Size')}
              htmlAttributes={{
                placeholder: 'Shoe Size',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Shoe Size', value)
              }))}
            />
          </div>
        </div>

        <div className='step4-inputs'>
          <div className='step4-inputs-two'>
            <InputBox
              value={getStatistic('Tee Shirt Size')}
              htmlAttributes={{
                placeholder: 'Tee Shirt Size',
              }}
              onChange={(value) => dispatch(setFormData({
                statistics: setStatistics('Tee Shirt Size', value)
              }))}
            />
          </div>
        </div>

        <div className='flex justify-between'>
          <Button
            variant='normal'
            handleClick={() => dispatch(setStep(3)) } 
            className='bg-gray-200 hover:bg-gray-300 text-xl mr-4'>
            Previous
          </Button>

          <Button
            variant='normal'
            handleClick={() => dispatch(setStep(5)) } 
            disabled={!isEveryUnitNotEmpty}
            className='bg-orange-400 hover:bg-orange-500 text-xl'>
            Next
          </Button>
        </div>

      </form >
    </div >
  )
}

export default Step6;