

const VARIANTS = {
  default: 'max-w-[32rem] sm:w-[32rem]',
  auto: ''
}

export default function TextInput({
  title, 
  name, 
  type='text', 
  placeholder,
  variant='default',
  value='',
  required,
  className,
  onChange}) 
  {
  return (
    <div className={`${VARIANTS[variant]} rounded-lg p-1 my-4 ${className}`}>
      <label className='text-gray-500 text-sm font-medium '>{title}{required && '*'}
      </label>
      <input
      onChange={onChange} 
      className='w-full h-[2.5rem]' 
      type={type} 
      name={name || ''}
      value={value}
      required={required}
      placeholder={placeholder} />
    </div>
  )
}